import axios, { AxiosInstance } from "axios";
import {
  User,
  Project,
  Account,
  Application,
  ContainerUsages,
  MetricDataList,
  Region,
  PaymentDetails,
  ProjectEvents,
  ManagedApplicationProvider,
  ManagedApplication,
  ManagedApplicationSnapshot,
  ManagedApplicationJob,
  BillingDetails,
} from "./model";
import { config } from "../../constants";

const DEFAULT_BASE_URL = config.API_BASE_URL;

export class AuthorizationFailedError extends Error {}

export class NotFoundError extends Error {}

export class BadRequestError extends Error {
  constructor(message) {
    super();
    this.message = message;
  }
}

export class ConflictError extends Error {
  constructor(message) {
    super();
    this.message = message;
  }
}

export class NitroAPI {
  baseURL = "";
  sessionToken = "";
  axios = AxiosInstance;

  constructor(baseURL) {
    this.baseURL = baseURL || DEFAULT_BASE_URL;
    this.axios = axios.create();
    this.axios.interceptors.request.use((req) => {
      if (this.sessionToken) {
        req.headers['authorization'] = this.sessionToken;
      }
      return req;
    });
    this.axios.interceptors.response.use(
      (resp) => {
        return resp;
      },
      (error) => {
        if (error.response && error.response.status == 401) {
          return Promise.reject(new AuthorizationFailedError());
        } else if (
          error.response &&
          error.response.status === 409 &&
          error.response.data
        ) {
          return Promise.reject(new ConflictError(error.response.data.message));
        } else if (
          error.response &&
          error.response.status === 400 &&
          error.response.data
        ) {
          return Promise.reject(
            new BadRequestError(
              error.response.data.message != null
                ? error.response.data.message
                : ""
            )
          );
        } else if (error.response && error.response.status === 404) {
          return Promise.reject(new NotFoundError());
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  setCredentials(sessionToken) {
    this.sessionToken = sessionToken;
  }

  async resetPassword(email, resetToken, newPassword) {
    const response = await this.axios.post(this.baseURL + "/v1/user/resetPassword", {
      email: email,
      token: resetToken,
      newPassword: newPassword,
    });
    return response.data;
  }

  async requestPasswordReset(email) {
    const response = await this.axios.post(this.baseURL + "/v1/user/requestPasswordReset", {
      email: email,
    });
    return response.data;
  }

  async login(email, password) {
    const response = await this.axios.post(this.baseURL + "/v1/user/login", {
      email: email,
      password: password,
    });
    return response.data;
  }

  async getUserInfo() {
    const response = await this.axios.get(this.baseURL + "/v1/user");
    return User.fromAPI(response.data);
  }

  async updateUserEmail(email) {
    const response = await this.axios.post(this.baseURL + "/v1/user/email", {
      emailAddress: email,
    });
    return response.data;
  }

  async updateUserPassword(currentPassword, newPassword) {
    const response = await this.axios.post(this.baseURL + "/v1/user/updatePassword", {
      currentPassword: currentPassword,
      newPassword: newPassword
    });
    return response.data;
  }

  async handleSAML(request) {
      const resp = await this.axios.post(this.baseURL + '/v1/auth/saml', {
          request: request
      });
      return resp.data;
  }


  async listProjects() {
    const response = await this.axios.get(this.baseURL + "/v1/projects");
    return response.data.projects.map(Project.fromAPI);
  }

  async listApplications(projectId) {
    const response = await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURIComponent(projectId) +
        "/applications"
    );
    return response.data.applications.map(Application.fromAPI);
  }

  async listManagedApplicationSnapshots(projectId, applicationId) {
    const response = await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURIComponent(projectId) +
        "/managed-applications/" +
        encodeURIComponent(applicationId) +
        "/snapshots"
    );
    return response.data.snapshots.map(ManagedApplicationJob.fromAPI);
  }

  async listManagedApplications(projectId) {
    const response = await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURIComponent(projectId) +
        "/managed-applications"
    );
    return response.data.applications
      .map(ManagedApplication.fromAPI)
      .filter((a) => !a.isDeleted());
  }

  async deleteManagedApplication(projectId, applicationId) {
    const response = await this.axios.delete(
      this.baseURL +
        "/v1/projects/" +
        encodeURIComponent(projectId) +
        "/managed-applications/" +
        encodeURIComponent(applicationId)
    );
    return;
  }

  async deleteApplication(projectId, applicationId) {
    const response = await this.axios.delete(
      this.baseURL +
        "/v1/projects/" +
        encodeURIComponent(projectId) +
        "/applications/" +
        encodeURIComponent(applicationId)
    );
    return;
  }

  async listAccounts() {
    const response = await this.axios.get(this.baseURL + "/v1/accounts");
    return response.data.accounts.map(Account.fromAPI);
  }

  async createProject(projectId, accountId) {
    const response = await this.axios.post(this.baseURL + "/v1/projects", {
      id: projectId,
      accountId: accountId,
    });
    return Project.fromAPI(response.data);
  }

  async deleteProject(projectId) {
    const response = await this.axios.delete(
      this.baseURL + "/v1/projects/" + encodeURIComponent(projectId)
    );
    return;
  }

  async validateNewEmail(email, token) {
    await this.axios.post(this.baseURL + "/v1/user/validateNewEmail", {
      emailAddress: email,
      validationToken: token,
    });
  }

  async validateEmail(email, token) {
    await this.axios.post(this.baseURL + "/v1/user/validateEmail", {
      emailAddress: email,
      validationToken: token,
    });
  }

  async createApplication(projectId, appId, manifest, region) {
    await this.axios.post(
      this.baseURL + "/v1/projects/" + encodeURI(projectId) + "/applications",
      {
        applicationId: appId,
        manifest: manifest,
        regionId: region,
      }
    );
  }

  async getRegions() {
    const resp = await this.axios.get(this.baseURL + "/v1/regions");
    return resp.data.regions.map(Region.fromAPI);
  }

  async getBillingDetails(accountId, upcomingInvoiceOnly) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/accounts/" +
        encodeURIComponent(accountId) +
        "/billingDetails?upcomingOnly=" + escape(upcomingInvoiceOnly ? "true" : "false")
    );
    return BillingDetails.fromAPI(resp.data);
  }

  async getPaymentDetails(accountId) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/accounts/" +
        encodeURIComponent(accountId) +
        "/paymentDetails"
    );
    return PaymentDetails.fromAPI(resp.data);
  }

  async updatePaymentDetails(
    accountId,
    name,
    address1,
    address2,
    city,
    state,
    country,
    stripeToken
  ) {
    const resp = await this.axios.post(
      this.baseURL +
        "/v1/accounts/" +
        encodeURIComponent(accountId) +
        "/paymentDetails",
      {
        name: name,
        addressLine1: address1,
        addressLine2: address2,
        city: city,
        stateProvince: state,
        country: country,
        stripeToken: stripeToken,
      }
    );
    return null;
  }

  async getManagedApplication(projectId, applicationId) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURIComponent(projectId) +
        "/managed-applications/" +
        encodeURIComponent(applicationId)
    );
    return ManagedApplication.fromAPI(resp.data.application);
  }

  async getManagedApplicationProviders() {
    const resp = await this.axios.get(
      this.baseURL + "/v1/managed-application-providers"
    );
    return resp.data.providers.map(ManagedApplicationProvider.fromAPI);
  }

  async getManagedApplicationProvider(providerId) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/managed-application-providers/" +
        encodeURIComponent(providerId)
    );
    return ManagedApplicationProvider.fromAPI(resp.data.provider);
  }

  async validateManagedApplicationDomain(domain, path) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/managed-application-domains/validate?domain=" +
        encodeURIComponent(domain) +
        "&path=" +
        encodeURIComponent(path)
    );
    return resp.data.available;
  }

  async deleteManagedApplicationSnapshot(projectId, applicationId, snapshotId) {
    const resp = await this.axios.delete(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/managed-applications/" +
        encodeURI(applicationId) +
        "/snapshots/" + encodeURIComponent(snapshotId),
      {}
    );
    return resp.data;
  }

  async snapshotManagedApplication(projectId, applicationId, name, clone) {
    const resp = await this.axios.post(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/managed-applications/" +
        encodeURI(applicationId) +
        "/operations/snapshot",
      {
        name: name,
        forClone: clone,
      }
    );
    return resp.data.snapshotId;
  }

  async getManagedApplicationSnapshot(projectId, snapshotId) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/snapshots/" +
        encodeURI(snapshotId)
    );
    return ManagedApplicationSnapshot.fromAPI(resp.data);
  }

  async updateManagedApplicationPlan(
    projectId,
    appId,
    plan
  ) {
    const resp = await this.axios.post(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/managed-applications/" +
        encodeURI(appId) +
        "/update-plan",
      {
        planId: plan,
      }
    );
    return resp.data;
  }

  async updateManagedApplicationDomain(
    projectId,
    appId,
    domainName,
    domainType,
    path
  ) {
    const resp = await this.axios.post(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/managed-applications/" +
        encodeURI(appId) +
        "/update-domain",
      {
        domain: domainName,
        domainType: domainType,
        path: path,
      }
    );
    return resp.data;
  }

  async launchManagedApplication(
    projectId,
    siteName,
    domainType,
    domainName,
    planId,
    providerId,
    regionId,
    configValues,
    billingType,
    restoreSnapshotId,
    path,
    updateStrategy
  ) {
    const resp = await this.axios.post(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/managed-applications",
      {
        siteName: siteName,
        domainType: domainType,
        domainName: domainName,
        planId: planId,
        providerId: providerId,
        regionId: regionId,
        configValues: configValues,
        billingType: billingType,
        restoreSnapshotId: restoreSnapshotId == null ? "" : restoreSnapshotId,
        path: path,
        updateStrategy: updateStrategy,
      }
    );
    return ManagedApplication.fromAPI(resp.data.application);
  }

  async getMetricsData(
    projectId,
    applicationId,
    metricName,
    aggregationType,
    start,
    end
  ) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/metrics/" +
        metricName +
        "?startTime=" +
        encodeURIComponent(start.toISOString()) +
        "&endTime=" +
        encodeURIComponent(end.toISOString()) +
        "&aggregationType=" +
        encodeURIComponent(aggregationType) +
        "&applicationId=" +
        encodeURIComponent(applicationId)
    );
    return MetricDataList.fromAPI(resp.data);
  }

  async getContainerUsage(accountId, start, end) {
    const resp = await this.axios.get(
      this.baseURL +
        "/v1/accounts/" +
        encodeURI(accountId) +
        "/containerUsage?startTime=" +
        encodeURIComponent(start.toISOString()) +
        "&endTime=" +
        encodeURIComponent(end.toISOString())
    );
    return ContainerUsages.fromAPI(resp.data);
  }

  async getLogs(projectId, appId, cb) {
    // todo - fix, this will grow
    let dataSoFar = 0;
    await this.axios.get(
      this.baseURL +
        "/v1/projects/" +
        encodeURI(projectId) +
        "/applications/" +
        encodeURI(appId) +
        "/logs",
      {
        responseType: "text",
        onDownloadProgress: function (progress) {
          if (progress.currentTarget.status == 200) {
            const lines = progress.currentTarget.responseText
              .substring(dataSoFar)
              .split("\n")
              .flatMap((line) => {
                try {
                  return [JSON.parse(line).result];
                } catch (e) {
                  return [];
                }
              });
            cb(lines.reverse());
            dataSoFar = progress.currentTarget.responseText.length;
          }
        },
      }
    );
  }

  async getProjectEvents(projectId) {
    const resp = await this.axios.get(
      this.baseURL + "/v1/projects/" + encodeURI(projectId) + "/events"
    );
    return ProjectEvents.fromAPI(resp.data);
  }

  async createUser(createUser) {
    await this.axios.post(this.baseURL + "/v1/user", {
      firstName: createUser.firstName,
      lastName: createUser.lastName,
      emailAddress: createUser.emailAddress,
      password: createUser.password,
      inviteCode: createUser.inviteCode,
      affiliateCode: createUser.affiliateCode,
    });
  }
}

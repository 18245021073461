import { Modal, Form, Input, Alert } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useContext, useState } from 'react';
import NitroContext from '../../context';
import { BadRequestError } from '../../service/nitroapi';

const CreateSnapshotModal = (props) => {
    const [form] = useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [globalState, dispatch] = useContext(NitroContext);
    const [errorMessage, setErrorMessage] = useState(null);

    const onOk = () => {
        form.submit();
    };

    const onSubmitForm = async (values) => {
        setConfirmLoading(true);
        try {
            await globalState.api.snapshotManagedApplication(props.project.id, props.application.applicationId, values.snapshotName, false);
            setConfirmLoading(false);
            setErrorMessage(null);
            if(props.onFinished) props.onFinished();
        }
        catch(e) {
            console.log(e);
            if(e instanceof BadRequestError) {
                setErrorMessage("Failed to create snapshot: " + e.message);
            }
            else {
                setErrorMessage("Unable to create snapshot, please try again later.");
            }
            setConfirmLoading(false);
        }
    };

            //<p>Snapshots are billed per GB/month. Pricing information can be found <a href="https://www.nitropowered.io/pricing#snapshots">here</a>.</p>
    return (
        <Modal title="Create Snapshot" okText="Create" visible={true} confirmLoading={confirmLoading} onOk={onOk} onCancel={props.onFinished}>
            <p>Type a snapshot name below and click <strong>Create</strong> to create a snapshot of your site.</p>
            <Form
                form={form}
                name="basic"
                onFinish={onSubmitForm}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
            >
                <Form.Item
                    label="Snapshot Name"
                    name="snapshotName"
                    rules={[{ required: true, message: 'Snapshot name is required' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
            {errorMessage != null ? <Alert type="error" message={errorMessage}/> : ''}
        </Modal>
    )
};

export default CreateSnapshotModal;
import React, { useContext, useState } from 'react';
import { Modal, Form, Radio, Input, Alert } from 'antd';
import AccountSelector from '../../components/account-selector';
import NitroContext from '../../context';
import { BadRequestError } from '../../service/nitroapi';

export const CreateProjectModal = (props) => {
    const [globalState, dispatch] = useContext(NitroContext);
    const [creating, setCreating] = useState(false);
    const [name, setName] = useState('');
    const [account, setAccount] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const onCreateButton = async () => {
        setCreating(true);
        try {
            const response = await globalState.api.createProject(name, account.id);
            props.onDismiss();
        }
        catch(e) {
            if(e instanceof BadRequestError && e.message) {
                setErrorMessage(e.message);
            } else {
                setErrorMessage('Unable to create project at this time. Please try again later.');
            }
            setCreating(false);
        }
    };

    const onCancelButton = () => {
        props.onDismiss();
    };

    const formLayout = 'horizontal';
    const formItemLayout =
      formLayout === 'horizontal'
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
          }
        : null;

    return (
        <Modal
            title="Create Project"
            visible={props.visible}
            onOk={onCreateButton}
            okText="Create"
            confirmLoading={creating}
            onCancel={onCancelButton}>
            <Form layout={formLayout}>
                <Form.Item label="Name" {...formItemLayout}>
                    <Input onChange={(e) => {setName(e.target.value)}} placeholder="" />
                </Form.Item>
                <Form.Item label="Account" {...formItemLayout} style={{display: 'none'}}>
                    <AccountSelector onAccountSelected={setAccount}/>
                </Form.Item>
            { errorMessage != '' ? <Alert message={errorMessage} type="error" showIcon /> : ''}
            </Form>
        </Modal>
    );
};
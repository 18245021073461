import React, { useContext, useState, useEffect } from 'react';
import NitroContext from '../../context';
import { Alert, Button, Descriptions, message } from 'antd';
import { Layout, Menu, Breadcrumb, Row, Col } from 'antd';

import { Modal, Input } from 'antd';

import { KeyOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { BadRequestError } from '../../service/nitroapi';
const { Header, Content, Footer } = Layout;


const SettingsPage = (props) => {
    const [changePasswordForm] = Form.useForm();
    const [accountSettingsForm] = Form.useForm();
    const [globalState, dispatch] = useContext(NitroContext);

    const [modalState, setModalState] = useState({});
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);

    const [showAccountSettingsForm, setShowAccountSettingsForm] = useState(false);
    const [accountSettingsErrorMessage, setAccountSettingsErrorMessage] = useState(null);

    const [infoMessage, setInfoMessage] = useState(null);

    const onSubmitAccountSettings = async (values) => {
        try {
            await globalState.api.updateUserEmail(values.email);
            setShowAccountSettingsForm(false);
            setInfoMessage('Your request to change your e-mail was processed. You will soon receive a link at your new e-mail address to confirm.');
        }
        catch(e) {
            if(e instanceof BadRequestError) {
                setAccountSettingsErrorMessage(e);
            }
            else {
                setAccountSettingsErrorMessage('Unable to update at this time.');
            }
        }
    };

    const onCancelChangePassword = () => {
        setModalState({});
        setChangePasswordVisible(false);
        changePasswordForm.resetFields();
    }

    const onChangePasswordOK = async () => {
        changePasswordForm.submit();
    }

    const confirmPasswordValidator = async (_, value, callback) => {
        if(value != changePasswordForm.getFieldValue('new_password')) {
            throw new Error('Passwords do not match.');
        }
    }

    const onFinishChangePassword = async () => {
        setModalState({updatingPassword: true, ...modalState});
        try {
            const resp = await globalState.api.updateUserPassword(changePasswordForm.getFieldValue('current_password'), 
                changePasswordForm.getFieldValue('new_password'));
            setModalState({});
            setChangePasswordVisible(false);
            changePasswordForm.resetFields();
            message.success('Password successfully updated.');
        }
        catch(e) {
            if(e instanceof BadRequestError) {
                setModalState({errorMessage: e.message, updatingPassword: false, ...modalState});
            }
            else {
                setModalState({errorMessage: 'Unable to update password at this time.', updatingPassword: false, ...modalState});
            }
        }
    };

    return (
        <div>
            <h2>Account Settings</h2>
            {infoMessage != null ? <Alert type="info" message={infoMessage}/> : ''}
            <p>Use this page to manage your account settings.</p>
            <Row>
            <Button type="primary" onClick={() => setChangePasswordVisible(true)} icon={<KeyOutlined/>}>Change Password</Button> &nbsp;
            <Button type="primary" onClick={() => setShowAccountSettingsForm(true)} icon={<KeyOutlined/>}>Change Email</Button><br/><br/>
            </Row>
            <Row>
            <Col span={12} xs={22}>
                <div className="card">
                <Descriptions title="Personal Information" style={{padding: '20px', backgroundColor: 'white'}}>
                    <Descriptions.Item label="Full Name">{globalState.userInfo.firstName} {globalState.userInfo.lastName}</Descriptions.Item>
                    <Descriptions.Item label="E-Mail">{globalState.userInfo.email}</Descriptions.Item>
                </Descriptions></div>
            </Col>
            </Row>

            {showAccountSettingsForm ? 
            <Modal visible={true} okText="Update" onOk={() => accountSettingsForm.submit()} onCancel={() => setShowAccountSettingsForm(false)}>
                <h3>Change Email</h3>
                {accountSettingsErrorMessage != null ? <Alert type="error" message={accountSettingsErrorMessage}/> : ''}
                <p>Fill out the form below to change your account email address.</p>
                <Form form={accountSettingsForm} onFinish={onSubmitAccountSettings} labelCol={{span: 9}}>
                    <Form.Item label="New email address" name="email"
                        rules={[{required: true, message: 'Email address is required' }]}>
                            <Input/>
                    </Form.Item>
                </Form>
            </Modal> : ''}

            <Modal okButtonProps={{disabled: modalState.updatingPassword, loading: modalState.updatingPassword}} visible={changePasswordVisible} onOk={onChangePasswordOK} onCancel={onCancelChangePassword}>
                <h3>Change Password</h3>
                <p>Fill out the form below to change your password.</p>
                <Form labelCol={{span: 9}} 
                    form={changePasswordForm} onFinish={onFinishChangePassword}>
                    <Form.Item label="Current Password" name="current_password"
                        rules={[{required: true, message: 'Current Password is required' }]}>
                            <Input.Password/>
                    </Form.Item>
                    <Form.Item label="New Password" name="new_password"
                        rules={[{min: 8, required: true, message: 'New Password must be at least 8 characters' }]}>
                            <Input.Password/>
                    </Form.Item>
                    <Form.Item label="Confirm New Password" name="new_password_confirm"
                        rules={[{ required: true, message: 'Confirm New Password is required'},
                             {validator: confirmPasswordValidator }]}>
                            <Input.Password/>
                    </Form.Item>
                </Form>
                {modalState.errorMessage != null ? <Alert message={modalState.errorMessage} type="error" /> : ''}
            </Modal>
        </div>
    );
}

export default SettingsPage;
import React, { useState, useContext, useEffect, useRef } from 'react';
import Title from 'antd/lib/typography/Title';
import ApplicationSelector from '../../components/application-selector';
import Text from 'antd/lib/typography/Text';
import NitroContext from '../../context';
import { List } from 'antd';
var Convert = require('ansi-to-html');
var convert = new Convert();

var loaded = true;

const LogsPage = (props) => {
    const [application, setApplication] = useState(null);
    const [globalState, dispatch] = useContext(NitroContext);
    const [{project: selectedProject}] = useContext(NitroContext);
    const [entries, setEntries] = useState([]);
    const [{callback}, setCallback] = useState({});
    const isMounted = useRef(true);
    const appId = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, [])

    const onReceiveLogs = (entries) => {
        const el =document.getElementById('logEntries');

        const newEntries = entries.map(e => convert.toHtml('[' + e.instance + ']: ' + e.entry)).join("<br/>");
        el.innerHTML = newEntries + "<br/>" + el.innerHTML;
    };

    const onChooseApplication = async (app) => {
        setApplication(app);
        document.getElementById('logEntries').innerHTML = '';
        appId.current = app.id;

        // TODO - nicely cancel this when component unmounts
        globalState.api.getLogs(selectedProject.id, app.id, (arg) => {
            if(app.id == appId.current) {
                if(isMounted.current) onReceiveLogs(arg);
            }
        });
    };

    return (
        <div>
            <Title level={2}>Logs</Title>
            <Text>Application ID: </Text><ApplicationSelector onApplicationSelected={onChooseApplication} width={200}/><br/><br/>
            <div style={{width: '90%', padding: '20px', background: 'black', color: 'white'}} id="logEntries" className="pre-scrollable">
                {entries.map(e => convert.toHtml(e))}
            </div>
        </div>
    );
};
export default LogsPage;
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Button, Form, Input, Checkbox, Col, Row, Alert } from 'antd';
import NitroContext from '../../context';
import { BadRequestError, ConflictError } from '../../service/nitroapi';
import Icon from '@ant-design/icons';

const ResetPassword = (props) => {
    const [isCreating, setIsCreating] = useState(false);
    const [globalState, dispatch] = useContext(NitroContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [resetSuccess, setRegisterSuccess] = useState(false);

    useEffect(() => {
        window.jQuery.Pages.init();
        window.themeInitialize();
    }, []);

    const onFinish = async (values) => {
        setIsCreating(true);
        setErrorMessage('');
          try {
              const resp = await globalState.api.requestPasswordReset(values.email);
              setIsCreating(false);
              setRegisterSuccess(true);
          } catch(e) {
            setErrorMessage('An unknown error occurred, please try again later.');
              setIsCreating(false);
          }
      };

    return (
    <div className="pageContainer" style={{background: '#f6f9fa'}}>
        <br/><br/>
    <div className="register-container full-height sm-p-t-30">
    <div className="d-flex justify-content-center flex-column full-height ">
        <img src="/assets/img/logo-dark.png" alt="logo" width="200"/>
        <br/>
        <h4>Enter your e-mail to request a password reset.</h4>
        { resetSuccess ? <Alert type="success" showIcon message="If the e-mail you provided belongs to a valid account, you will receive instructions to reset your password soon."/> :
        <Form onFinish={onFinish} className="login-form">
        <Row gutter={24}>
            <Col>
        <Form.Item name="email" style={{width: '300px'}}
            rules={[{ required: true, message: 'E-mail is required' }]}>
            <Input size="large"
              prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-mail"
            />
        </Form.Item>
        </Col>
        </Row>

        <Form.Item>
        { errorMessage != '' ?
        <div><Alert
            message={errorMessage}
            type="error"
            showIcon
        /><br/></div>: ''}
          <Button type="primary" htmlType="submit" loading={isCreating}>Reset Password</Button>
        </Form.Item>
      </Form>}

    </div>
    </div>
    <div className=" full-width">
    </div>
    </div>
);
};
export default ResetPassword;
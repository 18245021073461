import React, {useState, createContext, useContext, useReducer, useEffect} from 'react';
import NitroContext, {signIn, AuthenticationFailedError, signInSuccess} from '../../context';
import { NitroAPI, AuthorizationFailedError } from '../../service/nitroapi/index';
import { Link } from 'react-router-dom';
import { Alert } from 'antd';

const reducer = (state, action) => {
    switch (action.type) {
      case 'increment':
        return { count: state.count + 1 };

      default:
        return state;
    }
  };



const Login = props => {
    const [globalState, dispatch] = useContext(NitroContext);
    const [state, setState] = useState({
        email: '',
        password: '',
        error: ''
    });
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if(props.location && props.location.state && props.location.state.successMessage) {
            setSuccessMessage(props.location.state.successMessage);
            props.history.replace();
        }
    }, []);

    const onEmailChange = (event) => {
        setState({...state, email: event.target.value});
    }

    const onPasswordChange = (event) => {
        setState({...state, password: event.target.value});
    }

    const onSignIn = async (event) => {
        event.preventDefault();
        setSuccessMessage('');
        const api = new NitroAPI();
        //api.setCredentials(state.email, state.password);
        try {
            const resp = await api.login(state.email, state.password);
            api.setCredentials(resp.sessionToken);
            const userInfo = await api.getUserInfo();
            signInSuccess(dispatch, userInfo, {
                email: state.email,
                sessionToken: resp.sessionToken,
             });
        }
        catch(e) {
            if(e instanceof AuthorizationFailedError) {
                setState({...state, error: 'Invalid email/password.'});
            } else {
                setState({...state, error: 'Unable to sign in at this time.'});
            }
        }
    }

    return (
        <div className="login-wrapper">
        <div className="bg-pic" style={{backgroundColor: '#2f55d4'}}>
            {/*<img src="/assets/img/dc_bg.jpg" data-src="/assets/img/dc_bg.jpg" data-src-retina="/assets/img/dc_bg.jpg" alt="" className="lazy"/>*/}
            <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
            <h2 className="semi-bold text-white">
                        Nitro Powered makes it easy to run your applications, hands-off, fully managed.</h2>
            </div>
        </div>
        <div className="login-container bg-white">
            <div className="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <img src="/assets/img/logo-dark.png" alt="logo" width="175"/>
            <p className="p-t-35">Sign into your Nitro Powered account</p>
        <p>
            <Link to="/signup" className="text-info large"><strong>No Account? Click here to sign up.</strong></Link>
        </p>
            { state.error != '' ?
            <Alert message={state.error} type="error"/> : ''
            }
            { successMessage != '' ? <Alert message={successMessage} type="success"/> : ''}
            <form id="form-login" className="p-t-15" role="form" action="index.html">
                <div className="form-group form-group-default">
                <label>Login</label>
                <div className="controls">
                    <input type="text" name="email" placeholder="User Name" className="form-control" required onChange={onEmailChange}/>
                </div>
                </div>
                <div className="form-group form-group-default">
                <label>Password</label>
                <div className="controls">
                    <input type="password" className="form-control" name="password" placeholder="Credentials" onChange={onPasswordChange} required/>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6 no-padding sm-p-l-10">
                    <div className="checkbox ">
                    <input type="checkbox" value="1" id="checkbox1"/>
                    <label htmlFor="checkbox1">Keep Me Signed in</label>
                    </div>
                </div>
                </div>
                <button className="btn btn-primary btn-cons m-t-10" type="submit" onClick={onSignIn}>Sign in</button>
                <br/><br/><p>Forgot your password? Click <Link to="/resetPassword">here.</Link></p>
            </form>
            </div>
        </div>
        </div>
    );
}
export default Login;
import React, { useEffect, useContext, useState, useReducer } from "react";
import { SideNav } from "./sidenav";
import Dashboard from "../dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  useLocation,
} from "react-router-dom";
import NotFoundPage from "../notfound";
import NitroContext, { signOut, setProject, isAuthenticated, verifyCredentials, Reducer, isCredentialsVerified } from "../../context";
import ProjectSelector from "../../components/project-selector";
import ApplicationsPage from "../applications";
import ProjectsPage from "../projects";
import ApplicationsDeployPage from "../deploy";
import LogsPage from "../logs";
import SAMLAuthPage from "../saml-auth";
import BillingPage from "../billing";
import MonitoringPage from "../monitoring";
import SettingsPage from "../settings";
import { SitesPage, SiteLaunchPage } from "../sites";
import ViewSitePage from "../sites/view";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Menu, Dropdown, Spin, Skeleton } from 'antd';
import { DownOutlined } from '@ant-design/icons';
var moment = require("moment");

const AdminConsole = (props) => {
  const DynamicSiteBreadcrumb = ({ match }) => <span>View Site</span>;
  const customBreadcrumbs = [
    { path: "/sites/:id", breadcrumb: DynamicSiteBreadcrumb },
  ];

  const [isVerifyingCredentials, setIsVerifyingCredentials] = useState(true);
  const [globalState, dispatch] = useContext(NitroContext);
  const [projectEvents, setProjectEvents] = useState([]);
  const [projects, setProjects] = useState([]);
  const breadcrumbs = useBreadcrumbs(customBreadcrumbs);
  const location = useLocation();

  useEffect(() => {
    if(isAuthenticated(globalState) && !isCredentialsVerified(globalState)) {
      setIsVerifyingCredentials(true);
      verifyCredentials(dispatch, globalState);
    }
  }, []);

  useEffect(() => {
    if(isCredentialsVerified(globalState)) {
      setIsVerifyingCredentials(false);
      reloadProjects();
    }
  }, [globalState]);

  useEffect(() => {
    window.jQuery.Pages.init();
    window.jQuery.DashboardInit();
    window.themeInitialize();
  }, []);

  useEffect(() => {
    reloadProjects();
    return undefined;
  }, []);

  const reloadProjects = async () => {
    try {
      const _resp = await globalState.api.listProjects();
      const resp = _resp.filter((p) => p.isAvailable());

      const oldProjects = projects;
      setProjects(resp);
    } catch (e) {}
  };

  const onProjectSelected = async (project) => {
    setProject(dispatch, project);

    const events = await globalState.api.getProjectEvents(project.id);
    setProjectEvents(events.events);
  };

  const getRoutes = (type) => {
    const s = (
      <Switch>
        <Route exact path="/" render={(props) => <Dashboard {...props} />} />
        <Route exact path="/applications" component={ApplicationsPage} />
        <Route
          exact
          path="/applications/deploy"
          component={ApplicationsDeployPage}
        />
        <Route exact path="/logs" component={LogsPage} />
        <Route exact path="/billing" component={BillingPage} />
        <Route exact path="/monitoring" component={MonitoringPage} />
        <Route exact path="/projects" component={ProjectsPage} />
        <Route exact path="/settings" component={SettingsPage} />
        <Route exact path="/sites" component={SitesPage} />
        <Route exact path="/sites/launch" component={SiteLaunchPage} />
        <Route exact path="/sites/:id" component={ViewSitePage} />
        <Route exact path="/auth/saml" component={SAMLAuthPage}/>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    );

    return s;
  };

  const onClickLogout = () => {
    signOut(dispatch);
  };

  const topRightMenu = (
    <Menu>
      <Menu.Item>
        <a target="#" onClick={onClickLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <SideNav />
      <div className="page-container ">
        <div className="header" style={{justifyContent: 'space-between', alignItems: 'center'}}>
          <div>
          <a
            href="#"
            className="btn-link toggle-sidebar d-lg-none pg pg-menu"
            data-toggle="sidebar"
          ></a>
              <img
                src="/assets/img/logo-dark.png"
                alt="logo"
                data-src="/assets/img/logo-dark.png"
                style={{display: 'inline-block', maxWidth: '75px', height: 'auto', float: 'right', paddingLeft: '5px', paddingTop: '3px'}}
              />
            <span
              style={{
                display: projects.length <= 1 ? "none" : "inline",
                paddingLeft: "30px",
              }}
              className=""
            >
              <div><strong>Project:</strong>{" "}
              <ProjectSelector
                width={400}
                onProjectSelected={onProjectSelected}
              /></div>
            </span>
          </div>

          <div className="d-flex align-items-center">
            <div className="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none m-l-20">
              <span className="semi-bold">
                
              </span>
            </div>
            <Dropdown overlay={topRightMenu}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {isVerifyingCredentials ? '' :
               <span>{globalState.userInfo.firstName} {globalState.userInfo.lastName} <DownOutlined /></span>}
            </a>
            </Dropdown>&nbsp;&nbsp;
            <div className="pull-right d-lg-inline-block d-none">
              <button
                className="profile-dropdown-toggle"
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="thumbnail-wrapper d32 circular inline">
                  {isVerifyingCredentials ? <Skeleton avatar active/> :
                  <img
                    src={globalState.userInfo.getProfileImageURL()}
                    width="32"
                    height="32"
                  />}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="page-content-wrapper ">
          <div className="content sm-gutter">
            <div data-pages="parallax" className="jumbotron">
              <div className="container-fluid contaienr-fixed-lg p-l-25 p-r-25 sm-p-l-0 sm-p-r-0">
                <div className="inner">
                  {location.key != null && breadcrumbs.length > 0 ? (
                    <ol className="breadcrumb sm-p-b-5">
                      {breadcrumbs.map((b, idx) => {
                        if (idx == breadcrumbs.length - 1) {
                          return (
                            <li key={idx} className="breadcrumb-item active">
                              {b.breadcrumb}
                            </li>
                          );
                        } else {
                          return (
                            <li key={idx} className="breadcrumb-item">
                              <Link to={b.match.url}>{b.breadcrumb}</Link>
                            </li>
                          );
                        }
                      })}
                    </ol>
                  ) : (
                    <br />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid container-fixed-lg">
              <br />
              {isVerifyingCredentials ? 
              <div>
                <h3>Loading... <Spin size="large"/></h3>
              <Skeleton/>
              </div>
              :
              getRoutes()}
            </div>
          </div>

          <div className="container-fluid footerd">
            <div className="row copyright sm-text-center justify-content-md-center" style={{justifyContent: 'center'}}>
              <p className="small no-margin pull-left sm-pull-reset text-center">
                <span className="hint-text">
                  Copyright &copy; 2021 Nitro Powered. All rights reserved.{" "}
                </span>
                <span className="sm-block">
                  <a href="https://www.nitropowered.io/tos" className="m-l-10 m-r-10">
                    Terms of Service
                  </a>{" "}
                  <span className="muted">|</span>{" "}
                  <a href="https://www.nitropowered.io/privacy-policy" className="m-l-10">
                    Privacy Policy
                  </a>
                </span>
              </p>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default withRouter(AdminConsole);

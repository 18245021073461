import { Table, Divider, Tag, Row, Spin, Col, Button } from "antd";
import React, { useContext, useState, useEffect } from "react";
import Title from "antd/lib/typography/Title";
import NitroContext from "../../context";
import { Link } from "react-router-dom";

const ApplicationsPage = (props) => {
  const [globalState] = useContext(NitroContext);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [{ project: selectedProject }] = useContext(NitroContext);
  const [deletingApps, setDeletingApps] = useState({});

  useEffect(() => {
    if (globalState.project) reloadApps();
    return undefined;
  }, [selectedProject]);

  const reloadApps = async () => {
    try {
      const applications = await globalState.api.listApplications(
        globalState.project.id
      );
      setApplications(applications);
      setLoading(false);
      let shouldReload = false;
      applications.forEach((a) => {
        if (a.inSomePendingStatus()) shouldReload = true;
      });
      if (shouldReload) {
        setTimeout(reloadApps, 3000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickDelete = async (app) => {
    try {
      setDeletingApps({ ...deletingApps, [app.id]: true });
      const response = await globalState.api.deleteApplication(
        selectedProject.id,
        app.id
      );
    } catch (e) {}
    setTimeout(reloadApps, 3000);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a href="#">{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Frontends",
      dataIndex: "frontends",
      key: "frontends",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          {record.app.canBeDeleted() && !deletingApps[record.app.id] ? (
            <a
              href="#"
              onClick={() => {
                onClickDelete(record.app);
              }}
            >
              Delete
            </a>
          ) : (
            <Spin />
          )}
        </span>
      ),
    },
  ];

  const data = applications.map((a) => {
    const frontends = a.state.frontends.map((f) => {
      return (
        <Row key={f.name}>
          <span>{f.name}</span>
          <Divider type="vertical" />
          <a target="_blank" href={f.endpoint}>
            {f.endpoint}
          </a>
        </Row>
      );
    });
    return {
      key: a.id,
      name: a.id,
      status: <Tag color="geekblue">{a.status.toUpperCase()}</Tag>,
      region: <Tag color="green">{a.region.toLowerCase()}</Tag>,
      frontends: frontends,
      action: a,
      app: a,
    };
  });

  return (
    <div>
      <Title level={2}>Applications</Title>
      {loading ? (
        <Row justify="center" type="flex">
          <Col>
            <Spin tip="Loading..." />
          </Col>
        </Row>
      ) : (
        <div>
          <Col>
            <Link
              to={{
                pathname: "/applications/deploy",
                search: "?from=" + encodeURIComponent("marketplace"),
              }}
            >
              <Button type="primary" size="default">
                Deploy from Library
              </Button>
            </Link>
            <br />
            <br />
          </Col>
          <Col>
            <Table className="table" columns={columns} dataSource={data} />
          </Col>
        </div>
      )}
    </div>
  );
};
export default ApplicationsPage;

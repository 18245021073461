import React, { useState, useEffect, useContext } from "react";
import { Select, Spin } from "antd";
import NitroContext from "../../context";

const { Option } = Select;

const ProjectSelector = (props) => {
  const { width = 300, onProjectSelected } = props;
  const [globalState] = useContext(NitroContext);
  const [projects, setProjects] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reloadProjects();
  }, [globalState]);

  const reloadProjects = async () => {
    try {
      setLoading(true);
      const _resp = await globalState.api.listProjects();
      const resp = _resp.filter((p) => p.isAvailable());

      const oldProjects = projects;
      setProjects(resp);
      setLoading(false);
      console.log(_resp);
      if (resp.length > 0 && oldProjects.length == 0) {
        setSelectedValue(0);
        if (onProjectSelected) {
          onProjectSelected(resp[0]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = (value) => {
    if (onProjectSelected) {
      onProjectSelected(projects[value]);
    }
    setSelectedValue(value);
  };

  const onSearch = (val) => {};

  return (
    <Select
      autoComplete="newpassword"
      showSearch
      style={{ width: width }}
      placeholder="Select a project"
      loading={loading}
      optionFilterProp="children"
      defaultActiveFirstOption={true}
      onChange={onChange}
      value={selectedValue}
      onFocus={reloadProjects}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {projects.map((p, idx) => {
        return (
          <Option key={p.id} value={idx}>
            {p.id}
          </Option>
        );
      })}
    </Select>
  );
};
export default ProjectSelector;

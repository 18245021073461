import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Button, Form, Input, Checkbox, Col, Row, Alert } from 'antd';
import NitroContext from '../../context';
import { BadRequestError, ConflictError, NotFoundError } from '../../service/nitroapi';
import Icon from '@ant-design/icons';
import queryString from 'query-string';

const ResetPasswordConfirmPage = (props) => {
    const [isCreating, setIsCreating] = useState(false);
    const [globalState, dispatch] = useContext(NitroContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [resetSuccess, setRegisterSuccess] = useState(false);

    useEffect(() => {
        window.jQuery.Pages.init();
        window.themeInitialize();
    }, []);

    const onFinish = async (values) => {
        if(values.password != values.passwordConfirm) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        const token = props.match.params.token;
        const email = queryString.parse(window.location.search).email;

        setIsCreating(true);
        setErrorMessage('');
          try {
              const resp = await globalState.api.resetPassword(email, token, values.password);
              setIsCreating(false);
              setRegisterSuccess(true);
          } catch(e) {
              if(e instanceof NotFoundError) {
                setErrorMessage('Password reset URL is expired or invalid.');
              }
              else {
                setErrorMessage('An unknown error occurred, please try again later.');
              }
              setIsCreating(false);
          }
      };

    return (
    <div className="pageContainer" style={{background: '#f6f9fa'}}>
        <br/><br/>
    <div className="register-container full-height sm-p-t-30">
    <div className="d-flex justify-content-center flex-column full-height ">
        <img src="/assets/img/logo-dark.png" alt="logo" width="200"/>
        <br/>
        <h4>Enter your new password below to complete the password reset process.</h4>
        { resetSuccess ? <div>
            <Alert type="success" showIcon message={<p>Your password has been successfully reset <Link to="/">Click here to login.</Link>.</p>}/>
            </div> :
        <Form onFinish={onFinish} className="login-form">
        
        <Form.Item name="password"
            rules={[{ required: true, message: 'Please input your Password!' }, {message: 'Password must be 8 characters', min: 8 }]}>
            <Input size="large"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password (min 8 characters)"
            />
        </Form.Item>

        <Form.Item name="passwordConfirm"
            rules={[{ required: true, message: 'Confirm Password!'}]}>
            <Input size="large"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Confirm Password"
            />
        </Form.Item>

        <Form.Item>
        { errorMessage != '' ?
        <div><Alert
            message={errorMessage}
            type="error"
            showIcon
        /><br/></div>: ''}
          <Button type="primary" htmlType="submit" loading={isCreating}>Reset Password</Button>
        </Form.Item>
      </Form>}

    </div>
    </div>
    <div className=" full-width">
    </div>
    </div>
);
};
export default ResetPasswordConfirmPage;
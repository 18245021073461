import React from 'react';
import { isTSCallSignatureDeclaration } from '@babel/types';
import { AuthorizationFailedError, NitroAPI } from '../service/nitroapi';


const api = new NitroAPI();

const email = window.localStorage.getItem("email");
if(email != null) {
    api.setCredentials(window.localStorage.getItem("sessionToken"));
}

const NitroContext = React.createContext({
    authenticated: email != null,
    credentialsVerified: false,
    api: api,
    userInfo: {firstName: '', lastName: '', accountStatus: '', getProfileImageURL: () => {return ""; }},
    getEmail: () => {
        return window.localStorage.getItem("email");
    }
});

export default NitroContext;

const SIGN_IN_SUCCESS = "signinSuccess";
const SIGN_OUT = "signOut";
const CREDENTIALS_VERIFY_SUCCESS = "credentialsVerifySuccess";
const CREDENTIALS_VERIFY_FAIL = "credentialsVerifyFail";
const SET_PROJECT = "setProject";

export const Reducer = (state, action) => {
  switch(action.type) {
    case SIGN_IN_SUCCESS:
        state.api.setCredentials(action.sessionToken);
        window.localStorage.setItem("email", action.email);
        window.localStorage.setItem("sessionToken", action.sessionToken);
        window.location.href = '/';
        return {};
    case CREDENTIALS_VERIFY_SUCCESS:
        return {...state, credentialsVerified: true, userInfo: action.userInfo};
    case CREDENTIALS_VERIFY_FAIL:
        return {...state, authenticated: false, credentialsVerified: false};
    case SET_PROJECT:
        return {...state, project: action.project};
    case SIGN_OUT:
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("sessionToken");
        window.location.href = '/';
        return {};
    default:
        console.log('unknown action type: ' + action.type);
        return state;
    }
};

export class AuthenticationFailedError extends Error {}
export const signInSuccess = (dispatch, userInfo, info) => {
    dispatch({
        type: SIGN_IN_SUCCESS,
        email: info.email,
        sessionToken: info.sessionToken,
        userInfo: userInfo,
    });
};

export const signOut = (dispatch) => {
    dispatch({
        type: SIGN_OUT
    });
};

export const verifyCredentials = async (dispatch, state) => {
    try {
        const userInfo = await state.api.getUserInfo();
        console.log(userInfo);
        dispatch({
            type: CREDENTIALS_VERIFY_SUCCESS,
            userInfo: userInfo,
        });
    }
    catch(e) {
        console.log('Failed to verify credentials');
        if(!(e instanceof AuthorizationFailedError) && !e.response) {
            // temporary error, show loading
            setTimeout(() => {
                verifyCredentials(dispatch, state);
            }, 1000);
            return;
        }
        dispatch({
            type: CREDENTIALS_VERIFY_FAIL
        });
    }
};

export const setProject = (dispatch, project) => {
    dispatch({
        type: SET_PROJECT,
        project: project
    });
};

export const isCredentialsVerified = (state) => {
    return state.credentialsVerified;
};

export const isAuthenticated = (state) => {
    return state.authenticated;
}
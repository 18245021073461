import { Alert } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import React, { useContext, useState } from "react";
import NitroContext from "../../context";
import { BadRequestError } from "../../service/nitroapi";
import { DomainNameType } from "../../service/nitroapi/model";
import DomainSelector from "../domain-selector";

const ChangeDomain = (props) => {
  const [form] = useForm();
  const [globalState] = useContext(NitroContext);
  const [errorMessage, setErrorMessage] = useState(null);

  const onChangeButton = async () => {
    form.submit();
  };

  const onFinish = async () => {
    try {
      const domainType =
        form.getFieldValue("useCustomDomain") === true
          ? DomainNameType.CUSTOM
          : DomainNameType.PROVIDED;
      const domainName =
        domainType === DomainNameType.CUSTOM
          ? form.getFieldValue("customDomain")
          : form.getFieldValue("subdomain");
      const path =
        domainType === DomainNameType.CUSTOM
          ? form.getFieldValue("customDomainPath")
          : "";
      await globalState.api.updateManagedApplicationDomain(
        props.project.id,
        props.application.applicationId,
        domainName,
        domainType,
        path
      );
      if (props.onFinished) props.onFinished();
    } catch (e) {
      console.log(e);
      if (e instanceof BadRequestError) {
        setErrorMessage("Unable to update domain: " + e.message);
      } else {
        setErrorMessage("Unable to update domain at this time.");
      }
    }
  };

  return (
    <Modal
      title="Change Domain"
      visible={props.visible}
      onOk={onChangeButton}
      onCancel={props.onCancel}
      okText="Change"
      width={props.width ? props.width : 600}
    >
      <Form form={form} onFinish={onFinish}>
        <DomainSelector
          useCustomDomainFieldName="useCustomDomain"
          customDomainFieldName="customDomain"
          customDomainPathFieldName="customDomainPath"
          subdomainFieldName="subdomain"
        />
      </Form>
      {errorMessage != null ? (
        <Alert message={errorMessage} type="error" />
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ChangeDomain;

import React, {useContext, useReducer, useEffect, useState} from 'react';
import logo from './logo.svg';
import 'antd/dist/antd.css';
import './App.css';
import Login from './pages/login';
import NitroContext, {Reducer, isAuthenticated, isCredentialsVerified, verifyCredentials} from './context';
import AdminConsole from './pages/admin';
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";
//import { HashRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";



import Signup from './pages/signup';
import ActivateAccountPage from './pages/activate-account';
import ResetPassword from './pages/reset-password';
import ResetPasswordConfirmPage from './pages/reset-password-confirm';
import ConfirmEmailPage from './pages/confirm-email';

function App() {
  const initialState = useContext(NitroContext);
  const [state, dispatch] = useReducer(Reducer, initialState);
  const [isVerifyingCredentials, setIsVerifyingCredentials] = useState(true);

  useEffect(() => {
    if(isAuthenticated(state) && !isCredentialsVerified(state)) {
      setIsVerifyingCredentials(true);
      verifyCredentials(dispatch, state);
    }
  }, []);

  if(process.env.NODE_ENV == 'production') {
    console.log = function() { };
  }

  return (
    <NitroContext.Provider value={[state, dispatch]}>
      { isAuthenticated(state) ?
        <Router>
          <Switch>
            <Route exact path="/account/confirm-email/:token" component={ConfirmEmailPage}/>
            <Route render={() => (<AdminConsole/>)}/>
          </Switch>
        </Router>
        :
       <Router>
         <Switch>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/resetPassword" component={ResetPassword}/>
          <Route exact path="/signup" component={Signup}/>
          <Route exact path="/account/confirm/:token" component={ActivateAccountPage}/>
          <Route exact path="/account/confirm-email/:token" component={ConfirmEmailPage}/>
          <Route exact path="/account/passwordResetConfirm/:token" component={ResetPasswordConfirmPage}/>
          <Route render={() => (<Redirect to="/login"/>)}/>
         </Switch>
       </Router>
       }
    </NitroContext.Provider>
    /*
    <NitroContext.Provider value={[state, dispatch]}>
      { isAuthenticated(state) ?
      (isCredentialsVerified(state) ?
        <Router><AdminConsole/></Router> :
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
       : (isVerifyingCredentials ? 
        <Skeleton/>
        :
       <Router>
         <Switch>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/signup" component={Signup}/>
          <Route exact path="/account/confirm/:token" component={ActivateAccountPage}/>
          <Route render={() => (<Redirect to="/login"/>)}/>
         </Switch>
       </Router>
       )}
    </NitroContext.Provider>
    */
  );
}

export default App;

import React, { useState, useContext, useEffect } from "react";
import { Input, Spin } from "antd";
import { Tag, Divider } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

const InputAPIValidator = React.forwardRef((props, ref) => {
  const { onValidate, ...rest } = props;
  const VALIDATION_NONE = 1;
  const VALIDATION_LOADING = 2;
  const VALIDATION_VALID = 3;
  const VALIDATION_INVALID = 4;

  const [currentValue, setCurrentValue] = useState("");
  const [validationState, setValidationState] = useState(VALIDATION_NONE);

  const onChange = async (e) => {
    if (props.onChange && e && e.target) {
      props.onChange(e);
    }
    const value = e == null || !e.target ? currentValue : e.target.value;
    setCurrentValue(value);
    if (value.length == 0) {
      setValidationState(VALIDATION_NONE);
      return;
    }
    if (onValidate) {
      setValidationState(VALIDATION_LOADING);
      try {
        const valid = await onValidate(value);
        if (valid) {
          setValidationState(VALIDATION_VALID);
        } else {
          setValidationState(VALIDATION_INVALID);
        }
      } catch (e) {
        setValidationState(VALIDATION_NONE);
      }
    }
  };

  useEffect(() => {
    onChange(currentValue);
  }, [props.additional]);

  return (
    <div>
      <Input {...rest} value={currentValue} onChange={onChange} ref={ref} />
      &nbsp;&nbsp;
      {(() => {
        if (validationState == VALIDATION_LOADING) {
          return (
            <span>
              <Spin />
              &nbsp; Checking name...
            </span>
          );
        } else if (validationState == VALIDATION_VALID) {
          return <Tag color="green">Available</Tag>;
        } else if (validationState == VALIDATION_INVALID) {
          return <Tag color="red">Unavailable</Tag>;
        } else {
          return null;
        }
      })()}
    </div>
  );
});

export default InputAPIValidator;

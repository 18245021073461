import React from 'react';
import { Spin } from 'antd';

const Loadable = (props) => {
    const {
        loading = false,
        children
    } = props;

    if(loading) {
        return (<div style={{textAlign: 'center'}}><Spin/></div>);
    } else {
        return children;
    }

};
export default Loadable;
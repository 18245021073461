import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const SideNav = (props) => {
  return (
    <nav className="page-sidebar" data-pages="sidebar">
      <div className="sidebar-header">
        <h2>
          <Link to="/"><img src="/assets/img/logo-dark.png"/></Link>
        </h2>
      </div>

      <div className="sidebar-menu">
        <br />
        <ul className="menu-items">
          <li className="divider">
            <div></div>
          </li>
          <li className="">
            <Link to="/">
              <span className="title">Dashboard</span>
            </Link>
            <span className="icon-thumbnail">
              <i data-feather="home"></i>
            </span>
          </li>
          <li className="">
            <Link to="/sites">
              <span className="title">Sites</span>
            </Link>
            <span className="icon-thumbnail">
              <i data-feather="server"></i>
            </span>
          </li>

          <li className="">
            <Link to="/settings">
              <span className="title">Account Settings</span>
            </Link>
            <span className="icon-thumbnail">
              <i data-feather="settings"></i>
            </span>
          </li>
          <li className="">
            <Link to="/billing">
              <span className="title">Billing</span>
            </Link>
            <span className="icon-thumbnail">
              <i data-feather="dollar-sign"></i>
            </span>
          </li>
          <li className="">
            <a href="https://nitropowered.myfreshworks.com/login/auth/1636437049806?client_id=41441690836303948&redirect_uri=https%3A%2F%2Fnitropowered.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback" target="_new">
              <span className="title">Support</span>
            </a>
            <span className="icon-thumbnail">
              <i data-feather="help-circle"></i>
            </span>
          </li>
        </ul>
        <div className="clearfix"></div>
      </div>
    </nav>
  );
};

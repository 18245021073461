import React from "react";
import { Checkbox, Form, Input, Select } from "antd";
import { ManagedApplicationFieldTypes } from "../../service/nitroapi/model";

const getInputForField = (field) => {
  var input = null;
  var defaultValue = "";
  defaultValue = field.defaultValue;
  var valuePropName = "value";
  var rules = [];

  if (field.required) {
    rules.push({
      required: "true",
      message: field.name + " is required",
    });
  }

  if (field.type == ManagedApplicationFieldTypes.BOOL) {
    input = <Checkbox />;
    defaultValue = field.defaultValue;
    valuePropName = "checked";
  } else if (field.type == ManagedApplicationFieldTypes.STRING) {
    input = <Input placeholder={field.description} />;
  } else if (field.type == ManagedApplicationFieldTypes.FLOAT) {
    input = <Input type="number" step="0.01" placeholder={field.description} />;
  } else if (field.type == ManagedApplicationFieldTypes.LONG) {
    input = <Input type="number" placeholder={field.description} />;
  } else if (field.type == ManagedApplicationFieldTypes.MULTI_STRING) {
    input = (
      <Select>
        {field.multiStringOptions.map((o) => (
          <Select.Option key={o.id} value={o.id}>
            {o.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
  return (
    <Form.Item
      rules={rules}
      valuePropName={valuePropName}
      initialValue={defaultValue}
      name={"appConfig-" + field.id}
      key={field.id}
      label={
        field.name +
        (field.description != "" ? " (" + field.description + ")" : "")
      }
    >
      {input}
    </Form.Item>
  );
};

const FieldEditor = (props) => {
  return <div>{props.fields.map((field) => getInputForField(field))}</div>;
};

export default FieldEditor;

import React, { useContext, useEffect, useState } from 'react';
import ApplicationSelector from '../../components/application-selector';
import Title from 'antd/lib/typography/Title';
import { LineChart, PieChart } from 'react-chartkick';
import 'chart.js'
import TimeSeriesChart from '../../components/timeseries';
import { Row, Col } from 'antd';

import { appleStock } from '@vx/mock-data';
import { maxHeaderSize } from 'http';
import NitroContext from '../../context';
import MetricDisplay, {formatPercent} from '../../components/metricdisplay';

const MonitoringPage = (props) => {
    const [globalState, dispatch] = useContext(NitroContext);

    const onSelectApplication = (app) => {

    };

    const endTime = new Date();
    const startTime = new Date(endTime);
    startTime.setHours(startTime.getHours() - 48);

    return (
        <div>
            <Title level={2}>Application Monitoring</Title>
            <strong>Application:</strong> <ApplicationSelector width={200} onApplicationSelected={onSelectApplication}/><br/><br/>
            <MetricDisplay height={600} project={globalState.project} metricName="application_container_cpu_usage" aggregationType="avg" startTime={startTime} endTime={endTime} valueType="float" valueFormat={formatPercent}/>
            <br/>
            <MetricDisplay height={600} project={globalState.project} metricName="requests_1min" aggregationType="sum" startTime={startTime} endTime={endTime} valueType="long"/>
        </div>
    );
};
export default MonitoringPage;
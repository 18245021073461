import React, { useEffect, useContext, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import NitroContext from '../../context';
import MetricDisplay, { formatPercent, formatBytes, formatDuration } from '../../components/metricdisplay/index';
import { List, Tag, Button } from 'antd';
import { Link } from 'react-router-dom';
import Loadable from '../../components/loadable';
import TimeRangePicker from '../../components/time-range-picker/index';
import { ReloadOutlined } from '@ant-design/icons';

const Dashboard = props => {
    const [globalState, dispatch] = useContext(NitroContext);

    const [applications, setApplications] = useState([]);
    const [applicationsLoading, setApplicationsLoading] = useState(true);

    const [currentCharges, setCurrentCharges] = useState(0);
    const [currentChargesLoading, setCurrentChargesLoading] = useState(true);

    const [endTime, setEndTime] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    useEffect(() => {
      if(!globalState.project) return;
      loadApplications(globalState.project);
    }, [globalState.project]);

    useEffect(() => {
      if(!globalState.userInfo) return;
      loadCurrentCharges(globalState.userInfo.accountId);
    }, [globalState.userInfo]);

    const loadCurrentCharges = async (accountId) => {
        try {
            setCurrentChargesLoading(true);
            const billingDetails = await globalState.api.getBillingDetails(globalState.userInfo.accountId, true);
            for(var x = 0; x < billingDetails.invoices.length; x++) {
              if(!billingDetails.invoices[x].paid) {
                setCurrentCharges(billingDetails.invoices[x].total);
              }
            }
            setCurrentChargesLoading(false);
        }
        catch(e) {
            console.log(e);
        }
    };

    const loadApplications = async (project) => {
      try {
        setApplicationsLoading(true);
        const apps = await globalState.api.listManagedApplications(project.id);
        setApplications(apps);
        setApplicationsLoading(false);
      }
      catch(e) {
        console.log(e);
      }
    };

    const onSelectTimeRange = (start, end) => {
      setStartTime(start);
      setEndTime(end);
    };

    const refreshDashboard = () => {
      setEndTime(new Date());
    };

    return (
        <div>
            <div className="row">
              <div className="col-sm-6 col-lg-6 col-xl-6 col-md-6  d-flex flex-column">

                <div className="card social-card share  full-width m-b-10 no-border" data-social="item">
                  <div className="card-header ">
                    <h5 className="text-primary pull-left fs-12">Sites <i
									className="fa text-complete fs-11"></i></h5>
                    <div className="clearfix"></div>
                  </div>
                  <div className="card-description">
                        <List
                          size="small"
                          footer={<div></div>}
                          dataSource={applications.slice(0, 5)}
                          loading={applicationsLoading}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                               title={(<Link to="/sites">{item.id}</Link>)}
                               description={(
                                <div>
                                  <span><Link to={{pathname: "/sites/" + encodeURIComponent(item.applicationId)}}>{item.applicationName}</Link> </span>
                                </div>
                               )}
                              >
                            </List.Item.Meta>
                                  <Tag color="geekblue">{item.status}</Tag><Tag color="green">{item.providerId}</Tag>
                            </List.Item>
                          )}
                        />
                  </div>
                  <div className="card-footer clearfix">
                    <div className="pull-left"><Link to="/sites">See all {applications.length}</Link>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>




              </div>
              <div className="col-sm-6 col-lg-6 col-xl-6 col-md-6 d-flex flex-column">

                <div className="card no-border widget-loader-bar m-b-10">
                  <div className="container-xs-height full-height">
                    <div className="row-xs-height">
                      <div className="col-xs-height col-top">
                        <div className="card-header  top-left top-right">
                          <div className="card-title">
                            <span className="font-montserrat fs-11 all-caps">Current Charges (this month) <i className="fa fa-chevron-right"></i>
				                        </span>
                          </div>
                          <div className="card-controls">
                            <ul>
                              <li><a href="#" className="portlet-refresh text-black" data-toggle="refresh"><i className="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row-xs-height">
                      <div className="col-xs-height col-top">
                        <div className="p-l-20 p-t-50 p-b-40 p-r-20">
                          <Loadable loading={currentChargesLoading}>
                            <h3 className="no-margin p-b-5">${currentCharges.toFixed(2)}</h3>
                          <span className="small hint-text pull-left"><Link to="/billing">View all charges</Link></span>
                          </Loadable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
            </div>
    );
};
export default Dashboard;

/*
              <div className="col-lg-6 m-b-10 d-flex">

                <div className="widget-11-2 card no-border card-condensed no-margin widget-loader-circle align-self-stretch d-flex flex-column">
                  <div className="card-header top-right">
                    <div className="card-controls">
                      <ul>
                        <li><a data-toggle="refresh" className="portlet-refresh text-black" href="#"><i
										className="portlet-icon portlet-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="padding-25">
                    <div className="pull-left">
                      <h2 className="text-success no-margin">webarch</h2>
                      <p className="no-margin">Today's sales</p>
                    </div>
                    <h3 className="pull-right semi-bold"><sup>
							<small className="semi-bold">$</small>
						</sup> 102,967
						</h3>
                    <div className="clearfix"></div>
                  </div>
                  <div className="auto-overflow widget-11-2-table">
                    <table className="table table-condensed table-hover">
                      <tbody>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-montserrat all-caps fs-12 w-50">Purchase CODE #2345</td>
                          <td className="text-right hidden-lg">
                            <span className="hint-text small">dewdrops</span>
                          </td>
                          <td className="text-right b-r b-dashed b-grey w-25">
                            <span className="hint-text small">Qty 1</span>
                          </td>
                          <td className="w-25">
                            <span className="font-montserrat fs-18">$27</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="padding-25 mt-auto">
                    <p className="small no-margin">
                      <a href="#"><i className="fa fs-16 fa-arrow-circle-o-down text-success m-r-10"></i></a>
                      <span className="hint-text ">Show more details of APPLE . INC</span>
                    </p>
                  </div>
                </div>

              </div>
            </div>


            <div className="row">
              <div className="col-lg-4 md-m-b-10">

                <div className=" card no-border  no-margin widget-loader-circle todolist-widget pending-projects-widget">
                  <div className="card-header ">
                    <div className="card-title">
                      <span className="font-montserrat fs-11 all-caps">
                  Recent projects <i className="fa fa-chevron-right"></i>
              </span>
                    </div>
                    <div className="card-controls">
                      <ul>
                        <li><a href="#" className="card-refresh text-black" data-toggle="refresh"><i
        className="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="">Ongoing projects at <span className="semi-bold">pages</span></h5>
                    <ul className="nav nav-tabs nav-tabs-simple m-b-20" role="tablist" data-init-reponsive-tabs="collapse">
                      <li className="nav-item"><a href="#pending" className="active" data-toggle="tab" role="tab" aria-expanded="true">Pending</a>
                      </li>
                      <li className="nav-item"><a href="#completed" data-toggle="tab" role="tab" aria-expanded="false">Completed</a>
                      </li>
                    </ul>
                    <div className="tab-content no-padding">
                      <div className="tab-pane active" id="pending">
                        <div className="p-t-15">
                          <div className="d-flex">
                            <span className="icon-thumbnail bg-master-light pull-left text-master">ws</span>
                            <div className="flex-1 full-width overflow-ellipsis">
                              <p className="hint-text all-caps font-montserrat  small no-margin overflow-ellipsis ">Revox Ltd
                              </p>
                              <h5 className="no-margin overflow-ellipsis ">Marketing Campaign for revox</h5>
                            </div>
                          </div>
                          <div className="m-t-15">
                            <p className="hint-text  small pull-left">71% compleated from total</p>
                            <a href="#" className="pull-right text-master"><i className="pg-more"></i></a>
                            <div className="clearfix"></div>
                          </div>
                          <div className="progress progress-small m-b-15 m-t-10">

                            <div className="progress-bar progress-bar-info" style={{width:"71%"}}></div>

                          </div>
                        </div>
                        <div className="p-t-15">
                          <div className="d-flex">
                            <span className="icon-thumbnail bg-warning-light pull-left text-master">cr</span>
                            <div className="flex-1 full-width overflow-ellipsis">
                              <p className="hint-text all-caps font-montserrat  small no-margin overflow-ellipsis ">Nike Ltd
                              </p>
                              <h5 className="no-margin overflow-ellipsis ">Corporate rebranding</h5>
                            </div>
                          </div>
                          <div className="m-t-15">
                            <p className="hint-text  small pull-left">20% compleated from total</p>
                            <a href="#" className="pull-right text-master"><i className="pg-more"></i></a>
                            <div className="clearfix"></div>
                          </div>
                          <div className="progress progress-small m-b-15 m-t-10">

                            <div className="progress-bar progress-bar-warning" style={{width:"20%"}}></div>

                          </div>
                        </div>
                        <a href="#" className="btn btn-block m-t-30">See all projects</a>
                      </div>
                      <div className="tab-pane" id="completed">
                        <div className="p-t-15">
                          <div className="d-flex">
                            <span className="icon-thumbnail bg-master-light pull-left text-master">ws</span>
                            <div className="flex-1 full-width overflow-ellipsis">
                              <p className="hint-text all-caps font-montserrat  small no-margin overflow-ellipsis ">Apple Corp
                              </p>
                              <h5 className="no-margin overflow-ellipsis ">Marketing Campaign for revox</h5>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          <div className="m-t-15">
                            <p className="hint-text  small pull-left">45% compleated from total</p>
                            <a href="#" className="pull-right text-master"><i className="pg-more"></i></a>
                            <div className="clearfix"></div>
                          </div>
                          <div className="progress progress-small m-b-15 m-t-10">

                            <div className="progress-bar progress-bar-info" style={{width:"45%"}}></div>

                          </div>
                        </div>
                        <div className="p-t-15">
                          <div className="d-flex">
                            <span className="icon-thumbnail bg-warning-light pull-left text-master">cr</span>
                            <div className="flex-1 full-width overflow-ellipsis">
                              <p className="hint-text all-caps font-montserrat  small no-margin overflow-ellipsis ">Yahoo Inc
                              </p>
                              <h5 className="no-margin overflow-ellipsis ">Corporate rebranding</h5>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          <div className="m-t-25">
                            <p className="hint-text  small pull-left">20% compleated from total</p>
                            <a href="#" className="pull-right text-master"><i className="pg-more"></i></a>
                            <div className="clearfix"></div>
                          </div>
                          <div className="progress progress-small m-b-15 m-t-10">

                            <div className="progress-bar progress-bar-warning" style={{width:"20%"}}></div>

                          </div>
                        </div>
                        <a href="#" className="btn btn-block m-t-30">See all projects</a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-8 sm-m-t-10">

                <div className="widget-13 card no-border  no-margin widget-loader-circle">
                  <div className="card-header  pull-up top-right ">
                    <div className="card-controls">
                      <ul>
                        <li><a href="#" className="card-refresh text-black" data-toggle="refresh"><i className="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="container-sm-height no-overflow">
                    <div className="row row-sm-height">
                      <div className="col-sm-5 col-lg-4 col-xlg-3 col-sm-height col-top no-padding">
                        <div className="card-header  ">
                          <div className="card-title">Menu clipping
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="nav nav-pills m-t-5 m-b-15" role="tablist">
                            <li className="active">
                              <a href="#tab1" data-toggle="tab" role="tab" className="b-a b-grey text-master">
                                            fb
                                        </a>
                            </li>
                            <li>
                              <a href="#tab2" data-toggle="tab" role="tab" className="b-a b-grey text-master">
                                            js
                                        </a>
                            </li>
                            <li>
                              <a href="#tab3" data-toggle="tab" role="tab" className="b-a b-grey text-master">
                                            sa
                                        </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div className="tab-pane active" id="tab1">
                              <h3>Facebook</h3>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Views</p>
                              <p className="all-caps font-montserrat  no-margin text-success ">14,256</p>
                              <br/>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Today</p>
                              <p className="all-caps font-montserrat  no-margin text-warning ">24</p>
                              <br/>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Week</p>
                              <p className="all-caps font-montserrat  no-margin text-success ">56</p>
                            </div>
                            <div className="tab-pane " id="tab2">
                              <h3>Google</h3>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Views</p>
                              <p className="all-caps font-montserrat  no-margin text-success ">14,256</p>
                              <br/>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Today</p>
                              <p className="all-caps font-montserrat  no-margin text-warning ">24</p>
                              <br/>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Week</p>
                              <p className="all-caps font-montserrat  no-margin text-success ">56</p>
                            </div>
                            <div className="tab-pane" id="tab3">
                              <h3>Amazon</h3>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Views</p>
                              <p className="all-caps font-montserrat  no-margin text-success ">14,256</p>
                              <br/>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Today</p>
                              <p className="all-caps font-montserrat  no-margin text-warning ">24</p>
                              <br/>
                              <p className="hint-text all-caps font-montserrat small no-margin ">Week</p>
                              <p className="all-caps font-montserrat  no-margin text-success ">56</p>
                            </div>
                          </div>
                        </div>
                        <div className="bg-master-light p-l-20 p-r-20 p-t-10 p-b-10 pull-bottom full-width hidden-xs">
                          <p className="no-margin">
                            <a href="#"><i className="fa fa-arrow-circle-o-down text-success"></i></a>
                            <span className="hint-text">Super secret options</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-7 col-lg-8 col-xlg-9 col-sm-height col-top no-padding relative">
                        <div className="bg-success widget-13-map">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>*/


              /*
                <div className="card social-card share  full-width m-b-10 no-border" data-social="item">
                  <div className="card-header clearfix">
                    <h5 className="text-success pull-left fs-12">Stock Market <i
									className="fa text-success fs-11"></i></h5>
                    <div className="pull-right small hint-text">
                      5,345 <i className="fa fa-comment-o"></i>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="card-description">
                    <h5 className='hint-text no-margin'>Apple Inc.</h5>
                    <h5 className="small hint-text no-margin">NASDAQ: AAPL - Nov 13 8:37 AM ET</h5>
                    <h3 className="m-b-0">111.25 <span className="text-success"><i className="fa fa-sort-up small text-success"></i> 0.15</span>
							</h3>
                  </div>
                  <div className="card-footer clearfix">
                    <div className="pull-left">by <span className="text-success">John Smith</span>
                    </div>
                    <div className="pull-right hint-text">
                      Apr 23
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>


                <div className="card social-card share share-other full-width m-b-10 d-flex flex-1 full-height no-border sm-vh-75" data-social="item">
                  <div className="circle" data-toggle="tooltip" title="Label" data-container="body">
                  </div>
                  <div className="card-content flex-1" data-pages-bg-image="/assets/img/social_new.jpg">
                    <ul className="buttons ">
                      <li>
                        <a href="#"><i className="fa fa-expand"></i>
									</a>
                      </li>
                      <li>
                        <a href="#"><i className="fa fa-heart-o"></i>
									</a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-description">
                    <p><a href="#">#TBT</a> :D</p>
                  </div>
                  <div className="card-footer clearfix">
                    <div className="time">few seconds ago</div>
                    <ul className="reactions">
                      <li><a href="#">5,345 <i className="fa fa-comment-o"></i></a>
                      </li>
                      <li><a href="#">23K <i className="fa fa-heart-o"></i></a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-header clearfix">
                    <div className="user-pic">
                      <img alt="Avatar" width="33" height="33" data-src-retina="/assets/img/profiles/avatar_small2x.jpg" data-src="/assets/img/profiles/avatar.jpg" src="/assets/img/profiles/avatar_small2x.jpg"/>
                    </div>
                    <h5>David Nester</h5>
                    <h6>Shared a link on your wall</h6>
                  </div>
                </div>*/

                /*

                <div className="card social-card share  full-width m-b-10 no-border" data-social="item">
                  <div className="card-header clearfix">
                    <h5 className="text-success pull-left fs-12">Stock Market <i
									className="fa text-success fs-11"></i></h5>
                    <div className="pull-right small hint-text">
                      5,345 <i className="fa fa-comment-o"></i>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="card-description">
                    <h5 className='hint-text no-margin'>Apple Inc.</h5>
                    <h5 className="small hint-text no-margin">NASDAQ: AAPL - Nov 13 8:37 AM ET</h5>
                    <h3 className="m-b-0">111.25 <span className="text-success"><i className="fa fa-sort-up small text-success"></i> 0.15</span>
							</h3>
                  </div>
                  <div className="card-footer clearfix">
                    <div className="pull-left">by <span className="text-success">John Smith</span>
                    </div>
                    <div className="pull-right hint-text">
                      Apr 23
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>


                <div className="card social-card share share-other full-width m-b-10 d-flex flex-1 full-height no-border sm-vh-75" data-social="item">
                  <div className="circle" data-toggle="tooltip" title="Label" data-container="body">
                  </div>
                  <div className="card-content flex-1" data-pages-bg-image="/assets/img/social_new.jpg">
                    <ul className="buttons ">
                      <li>
                        <a href="#"><i className="fa fa-expand"></i>
									</a>
                      </li>
                      <li>
                        <a href="#"><i className="fa fa-heart-o"></i>
									</a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-description">
                    <p><a href="#">#TBT</a> :D</p>
                  </div>
                  <div className="card-footer clearfix">
                    <div className="time">few seconds ago</div>
                    <ul className="reactions">
                      <li><a href="#">5,345 <i className="fa fa-comment-o"></i></a>
                      </li>
                      <li><a href="#">23K <i className="fa fa-heart-o"></i></a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-header clearfix">
                    <div className="user-pic">
                      <img alt="Avatar" width="33" height="33" data-src-retina="/assets/img/profiles/avatar_small2x.jpg" data-src="/assets/img/profiles/avatar.jpg" src="/assets/img/profiles/avatar_small2x.jpg"/>
                    </div>
                    <h5>David Nester</h5>
                    <h6>Shared a link on your wall</h6>
                  </div>
                </div>*/

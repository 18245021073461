import { Table, Divider, Tag, Row, Spin, Col, Button, Modal } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import NitroContext from '../../context';
import { CreateProjectModal } from './create';
import {
    ReloadOutlined,
    PlusOutlined
  } from '@ant-design/icons';


const ProjectsPage = (props) => {
    const [globalState, dispatch] = useContext(NitroContext);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCreateProject, setShowCreateProject] = useState(false);

    useEffect(() => {
        reloadProjects();
        return undefined;
    }, []);

    const reloadProjects = async () => {
        try {
            const projects = await globalState.api.listProjects();
            setProjects(projects);
            setLoading(false);
            let shouldReload = false;
            projects.forEach(p => {
                if(p.inSomePendingStatus()) shouldReload = true;
            });
            if(shouldReload) {
                setTimeout(reloadProjects, 3000);
            }
        }
        catch(e) {
            console.log(e);
        }
    };

    const onClickCreateProject = () => {
        setShowCreateProject(true);
    };

    const onClickDelete = async (project) => {
        try {
            const response = await globalState.api.deleteProject(project.id);
        }
        catch(e) {
            console.log(e);
        }
        setTimeout(reloadProjects, 3000);
    };

    const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <a href="#">{text}</a>,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
        <span>
            { record.project.canBeDeleted() ? <a href="#" onClick={() => {onClickDelete(record.project)}}>Delete</a> :
                (record.project.inSomePendingStatus() ? <Spin/> : '')}
        </span>
        ),
    },
    ];

    const data = projects.map(a => {
        return {
            key: a.id,
            name: a.id,
            account: a.accountId,
            status: (
                <Tag color="geekblue">
                    {a.status.toUpperCase()}
                </Tag>
            ),
            action: a,
            project: a,
        };
    });

    return (
        <div>
        <p>A project can be used to organize your sites.</p>
        <Row>
            { showCreateProject ?
                <CreateProjectModal visible={showCreateProject} onDismiss={() => {setShowCreateProject(false); reloadProjects();}}/> : ''}
            <Button icon={<PlusOutlined/>} type="primary" size='default' onClick={onClickCreateProject}>New Project</Button>
            <br/>
            <br/>
            { loading ?
            <Row justify="center" type="flex">
            <Col>
                <Spin tip="Loading..."/>
            </Col>
            </Row>
            : <div className="card"><div className="card-body"><Table bordered className="table" columns={columns} dataSource={data}/></div></div>}
        </Row>
        </div>
    );
};
export default ProjectsPage;
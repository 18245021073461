import React from 'react';
import Title from 'antd/lib/typography/Title';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const NotFoundPage = props => {
    return (
        <div>
            <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you are looking for was not found."
            extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
            />
        </div>
    );
};
export default NotFoundPage;
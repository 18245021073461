import { List, Divider, Card, Col, Row, Statistic, Alert, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { LeftOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

const PlanSelector = (props) => {
    const [planNotAvailableTrial, setPlanNotAvailableTrial] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(props.currentPlan);

    useEffect(() => {
        if(selectedPlan == null) setSelectedPlan(props.currentPlan);
    }, [props.currentPlan]);

    const selectPlan = (plan) => {
        setSelectedPlan(plan);
        if(props.onSelectPlan) props.onSelectPlan(plan);
    };
    return (
        <div>
            {planNotAvailableTrial ? <div><Alert type="error" message={(
                <div>This plan is only available for paid customers. Please <Link to="/billing">enter your billing information</Link> if you would like to select this plan.</div>
            )} /><br /></div> : ''}
            <Row justify="left" gutter={6}>
                {props.provider.plans.map((plan) => (
                    <Col key={plan.id} span={6} xs={24} sm={8}>
                        <Card onClick={() => selectPlan(plan)} hoverable>
                            <Card.Meta title={
                                selectedPlan != null && plan.id == selectedPlan.id ?
                                    <h4>{plan.name} Plan {props.currentPlan != null && props.currentPlan.id == plan.id ? '(Current)' : ''} <CheckCircleOutlined/></h4>
                                : (plan.name + ' Plan' + (props.currentPlan != null && props.currentPlan.id == plan.id ? ' (Current)' : ''))
                            }/> <br />
                            <Statistic
                                title="Billed Monthly"
                                valueStyle={{ fontSize: "1.2em" }}
                                value={"$" + plan.priceBilledMonthly + "/month"}
                            />
                            <Divider orientation="left">Features</Divider>
                            <List
                                size="small"
                                bordered
                                dataSource={plan.features}
                                renderItem={(item) => <List.Item>{item.name}</List.Item>}
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
};

export default PlanSelector;
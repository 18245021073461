import { Button, Modal, Form, Input, Radio, Row, Col, Select, Alert } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import {CardElement, injectStripe, Elements, StripeProvider} from 'react-stripe-elements';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import NitroContext from '../../context/index';
import { BadRequestError } from '../../service/nitroapi/index';
import FormItem from 'antd/lib/form/FormItem';
const { Option } = Select;

class PaymentDetailsForm extends React.Component {
  formRef = React.createRef();

  constructor (props) {
      super(props);
      this.state = {
          country: '',
          state: '',
          states: [],
          creditCardDetailsValid: null,
          loading: false,
          errorMessage: '',
      }
  }

  onCountryChange = (idx) => {
      const country = CountryRegionData[idx];
      const states = country[2].split("|").map(a => a.split("~")[1]);
      this.setState({
          country: country[0],
          states: states,
      });
  }

  onZipKeypress = (evt) => {
      var theEvent = evt || window.event;

      var regex = /[0-9]|\./;
      if( !regex.test(String.fromCharCode(theEvent.keyCode || theEvent.which)) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
      }
  };

  onZipChange = (evt) => {
  }

  onStateChange = (state) => {
      this.setState({state: state});
  }

  onCardChange = (evt) => {
      this.setState({cardComplete: evt.complete, creditCardDetailsValid: evt.complete});
  };


  onFinish = async (values) => {
      if (!this.state.cardComplete) {
          this.setState({errorMessage: '', creditCardDetailsValid: this.state.cardComplete || false});
          return;
      }

      this.setState({loading: true, errorMessage: ''});
      let {token} = await this.props.stripe.createToken({
          name: values.name,
          address_city: values.city,
          address_line1: values.address,
          address_line2: values.address2,
          address_country: values.country,
          address_state: values.state,
      });
      try {
        const resp = await this.props.globalState.api.updatePaymentDetails(this.props.globalState.userInfo.accountId, values.name, values.address, values.address2, values.city, values.state, values.country, token.id);
        this.props.onFinished();
      }
      catch(e) {
        if(e instanceof BadRequestError && e.message) {
          this.setState({errorMessage: e.message});
        } else {
          this.setState({errorMessage: 'Unable to update payment details now. Please try again later.'})
        }
        this.setState({loading: false});
      }
  };

  render() {
    const { loading, country, region } = this.state;
    const { visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        title="Update Credit Card"
        okText="Update"
        onCancel={onCancel}
        onOk={this.onSubmit}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button form="billingForm" key="submit" type="primary" loading={loading} htmlType="submit">
            Update
          </Button>]}
      >
        <Form id="billingForm" layout="vertical" onFinish={this.onFinish} ref={this.formRef}>
          <Form.Item label="Cardholder Name" name="name"
              rules={[{ required: true, message: 'Cardholder hame is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address"
              rules={[{ required: true, message: 'Address is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Address Line 2" name="address2">
            <Input />
          </Form.Item>
          <Form.Item label="City" name="city"
              rules={[{ required: true, message: 'City is required' }]}>
            <Input />
          </Form.Item>
              <Row gutter={2}>
                  <Col span={16}><span>Country</span></Col>
                  <Col span={8}><span>State</span></Col>
              </Row>
              <br/>
              <Row gutter={2}>
              <Col span={16}>
            <Form.Item name="country"
                getValueFromEvent={(evt) => CountryRegionData[evt][0]}
                rules={[{ required: true, message: 'Country is required' }]}>
                  <Select showSearch
                      placeholder="Country"
                      optionFilterProp="children"
                      autoComplete="none"
                      onChange={this.onCountryChange}
                  >
                  { CountryRegionData.map((cr, idx) => (
                      <Option key={idx} value={idx}>{cr[0]}</Option>
                  ))}
                  </Select>
            </Form.Item>
                </Col>
              <Col span={8}>
            <Form.Item name="state"
              rules={[{ required: true, message: 'State is required' }]}>
                  <Select showSearch
                      placeholder="State"
                      optionFilterProp="children"
                      autoComplete="none"
                      onChange={this.onStateChange}
                  >
                  { this.state.states.map((cr, idx) => (
                      <Option key={cr} value={cr}>{cr}</Option>
                  ))}
                  </Select>
              </Form.Item>
              </Col>
            </Row>
          <Form.Item label="Credit Card Details">
            { this.state.creditCardDetailsValid != null && !this.state.creditCardDetailsValid ?
            <div style={{color: 'red'}} className="ant-form-explain">Credit Card details are required.</div> : ''}
            <CardElement onChange={this.onCardChange}/>
          </Form.Item>
        </Form>
        {this.state.errorMessage != '' ? <Alert message={this.state.errorMessage} type="error" /> : ''}
      </Modal>
    );
  }
}

export default injectStripe(PaymentDetailsForm);
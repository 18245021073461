import { Alert, Form, formModal, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';

const ConfirmDeleteModal = (props) => {
    const [form] = useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const onFinish = async () => {
        setConfirmLoading(true);
        try {
            if(props.onDeleteConfirm) {
                await props.onDeleteConfirm();
            }
            setConfirmLoading(false);
            setErrorMessage(null);
            if(props.onFinished) props.onFinished();
        }
        catch(e) {
            setErrorMessage('Unable to delete at this time.');
            setConfirmLoading(false);
        }
    };

    return (
        <Modal title={props.title} onCancel={props.onCancel} confirmLoading={confirmLoading} onOk={() => form.submit()} okText="Delete" visible={true} okButtonProps={{ type: 'danger' }}>
            <p>Are you sure you want to delete <strong>{props.itemName}</strong>?</p>

            <p>Type <strong>delete</strong> in the box below to confirm.</p>
            <Form
                form={form}
                onFinish={onFinish}
                name="basic">
                <Form.Item
                    label=""
                    name="deleteConfirm"
                    rules={[{ required: true, message: 'You must type delete to confirm', pattern: "^delete$" }]}
                >
                    <Input />
                </Form.Item>
            </Form>
            {errorMessage != null ? <Alert type="error" message={errorMessage}/> : ''}
        </Modal>
    )
};

export default ConfirmDeleteModal;
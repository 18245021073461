import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spin, Button, Form, Input, Checkbox, Col, Row, Alert } from 'antd';
import NitroContext from '../../context';
import { BadRequestError, ConflictError } from '../../service/nitroapi';
import Icon from '@ant-design/icons';
import { useLocation } from 'react-router';

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const Signup = (props) => {
    const [isCreating, setIsCreating] = useState(false);
    const [globalState, dispatch] = useContext(NitroContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [registerSuccess, setRegisterSuccess] = useState(false);
    let query = useQuery();

    useEffect(() => {
        window.jQuery.Pages.init();
        window.themeInitialize();
    }, []);

    const onFinish = async (values) => {
        setIsCreating(true);
        setErrorMessage('');
          try {
              var affiliateCode = query.get('ref');
              if(affiliateCode == null || affiliateCode.length == 0) {
                  affiliateCode = '';
              }
              const resp = await globalState.api.createUser({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  emailAddress: values.email,
                  password: values.password,
                  affiliateCode: affiliateCode
              });
              setIsCreating(false);
              setRegisterSuccess(true);
          } catch(e) {
              if(e instanceof BadRequestError && e.message) {
                  setErrorMessage(e.message);
              } else if(e instanceof ConflictError) {
                  setErrorMessage('E-mail address already exists.');
              } else {
                  setErrorMessage('An unknown error occurred, please try again later.');
              }
              setIsCreating(false);
          }
      };

    return (
    <div className="pageContainer" style={{background: '#f6f9fa'}}>
        <br/><br/>
    <div className="register-container full-height sm-p-t-30">
    <div className="d-flex justify-content-center flex-column full-height ">
        <img src="/assets/img/logo-dark.png" alt="logo" width="200"/>
        <br/>
        <h3>Join Nitro Powered today -- let us manage your applications for you.</h3>
        <p>
        Create your Nitro Powered account.
        </p>
        { registerSuccess ? <Alert type="success" showIcon message="Your account was successfully created. Check your-email for activation instructions."/> :
        <Form onFinish={onFinish} className="login-form">
                <Row gutter={24}>
            <Col span={12}>
        <Form.Item name="firstName"
            rules={[{ required: true, message: 'First Name is required' }]}>
            <Input size="large"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="First Name"
            />
        </Form.Item>
            </Col>
            <Col span={12}>
        <Form.Item name="lastName"
            rules={[{ required: true, message: 'Last Name is required' }]}>
            <Input size="large"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Last Name"
            />
        </Form.Item>
            </Col>
            </Row>
        <Row gutter={24}>
            <Col>
        <Form.Item name="email"
            rules={[{ required: true, message: 'E-mail is required' }]}>
            <Input size="large"
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-mail"
            />
        </Form.Item>
        </Col>
        </Row>

        <Form.Item name="password"
            rules={[{ required: true, message: 'Please input your Password!' }, {message: 'Password must be 8 characters', min: 8 }]}>
            <Input size="large"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password (min 8 characters)"
            />
        </Form.Item>
        <Form.Item>
        { errorMessage != '' ?
        <div><Alert
            message={errorMessage}
            type="error"
            showIcon
        /><br/></div>: ''}
          <Button type="primary" htmlType="submit" loading={isCreating}>Create Account</Button>
        </Form.Item>
        <p>Already have an account? <Link to="/login">Click here to login</Link></p>
      </Form>}

    </div>
    </div>
    <div className=" full-width">
    </div>
    </div>
);
};
export default Signup;
import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Spin, Alert, Result } from 'antd';
import Icon from '@ant-design/icons';
import NitroContext, { signOut } from '../../context';
import queryString from 'query-string';
import { NotFoundError } from '../../service/nitroapi';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import { BadRequestError } from '../../service/nitroapi/index';
import { Redirect } from 'react-router-dom';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const ConfirmEmailPage = (props) => {
    const [isActivating, setIsActivating] = useState(true);
    const [globalState, dispatch] = useContext(NitroContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [isActivated, setIsActivated] = useState(false);

    useEffect(() => {
        activateAccount();
    }, []);

    const activateAccount = async () => {
        const token = props.match.params.token;
        const email = queryString.parse(window.location.search).email;
        if(!email) {
            setErrorMessage('Invalid confirmation link.');
            return;
        }

        try {
            setErrorMessage('');
            const resp = await globalState.api.validateNewEmail(email, token);
            setIsActivating(false);
            setIsActivated(true);
        }
        catch(e) {
            setIsActivating(false);
            setIsActivated(false);
            if(e instanceof NotFoundError) {
                setErrorMessage('Confirmation link expired or invalid.');
            } else {
                setErrorMessage('Unable to activate account at this time, please try again later.');
            }
        }
    };

    return (
        <div style={{height: '100%'}}>
            <Row type="flex" justify="center" gutter={24} align="middle" style={{height: '100%'}}>
                <Col>
                { isActivating ?
                    <Spin indicator={antIcon}/>: ''}
                { errorMessage != '' ?
                <Result
                status="error"
                title="Account Activation Failed"
                subTitle={errorMessage}
              >
              </Result> : ''}
                </Col>
            </Row>
            {isActivated ? <Redirect to={{pathname: "/login", state: {successMessage: "Account successfully updated, you can now login with your new e-mail address."}}}/> : ''}
        </div>
    );
};
export default ConfirmEmailPage;
import React, { useState, useContext, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import { Row, Col, Card, AutoComplete, Button, Form, Input, Alert, Select } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Meta from 'antd/lib/card/Meta';
import NitroContext from '../../context';
import yaml from 'js-yaml';
import { ConflictError } from '../../service/nitroapi';
import { Redirect } from 'react-router-dom';
import { BadRequestError } from '../../service/nitroapi/index';
import Icon from '@ant-design/icons';

const { Option } = Select;

const ApplicationsDeployPage = (props) => {
    const [deployState, setDeployState] = useState({
        phase: 'template'
    });
    const [globalState, dispatch] = useContext(NitroContext);
    const [{project: selectedProject}] = useContext(NitroContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [deployed, setDeployed] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);

    useEffect(() => {
        loadRegions();
    }, []);

    const loadRegions = async () => {
        try {
            const regions = await globalState.api.getRegions();
            setRegions(regions);
        } catch(e) {
            console.log(e);
        }
    };

    const onSelectTemplate = (tpl) => {
        setDeployState({...deployState, phase: 'details', selectedTemplate: tpl});
    };

    const onSelectRegion = (value) => {
        setSelectedRegion(regions[value].id);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        await props.form.validateFields(async (err, values) => {
          if (!err) {
            try {
                setIsCreating(true);
                const response = await globalState.api.createApplication(selectedProject.id, values.appId, yaml.safeLoad(atob(deployState.selectedTemplate.template)), selectedRegion);
                setDeployed(true);
            }
            catch(e) {
                setIsCreating(false);
                if(e instanceof ConflictError) {
                    setErrorMessage('Application ID already exists');
                } else if(e instanceof BadRequestError && e.message) {
                    setErrorMessage("Error: " + e.message);
                } else {
                    setErrorMessage('Unable to deploy application at this time');
                }
            }
          }
        });
      };

    const templates = [
        {
            id: 'wordpress',
            title: 'Wordpress',
            imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1200px-WordPress_blue_logo.svg.png',
            description: "The world's most popular blogging platform",
            template: 'ZnJvbnRlbmRzOgogIC0gbmFtZTogd29yZHByZXNzCiAgICB0eXBlOiBodHRwCiAgICBkZWZhdWx0OiB0cnVlCiAgICBkZXN0aW5hdGlvbnM6CiAgICAgIC0gdGFyZ2V0OiBzZXJ2aWNlOi8vd29yZHByZXNzOjgwCiAgICAgICAgd2VpZ2h0OiA3NQogICAgICAtIHRhcmdldDogc2VydmljZTovL3dvcmRwcmVzczo4MAogICAgICAgIHdlaWdodDogMjUKc2VydmljZXM6CiAgLSBuYW1lOiB3b3JkcHJlc3MKICAgIHRhcmdldDogY29udGFpbmVyOi8vd29yZHByZXNzOjgwCnNlY3JldHM6CiAgLSBuYW1lOiBteXNxbC5wYXNzd29yZAogICAgbWFuYWdlZDogdHJ1ZQphZGRvbnM6CiAgLSBuYW1lOiBkYgogICAgdHlwZTogbXlzcWwKICAgIGNvbmZpZzoKICAgICAgdHlwZTogbTEuc21hbGwKICAgICAgc3RvcmFnZTogIjEiCiAgICAgIGRhdGFiYXNlOiB3b3JkcHJlc3MKY29udGFpbmVyczoKICAtIG5hbWU6IHdvcmRwcmVzcwogICAgaW1hZ2U6IHdvcmRwcmVzcwogICAgdHlwZTogYzEuc21hbGwKICAgIGF1dG9zY2FsaW5nOgogICAgICBlbmFibGVkOiB0cnVlCiAgICAgIHR5cGU6IGNwdQogICAgICBtaW46IDIKICAgICAgbWF4OiA0CiAgICBoZWFsdGhDaGVjazoKICAgICAgcGF0aDogLwogICAgICBwb3J0OiA4MAogICAgZW52aXJvbm1lbnQ6CiAgICAgIFdPUkRQUkVTU19EQl9IT1NUOiAkYWRkb24uZGIuaG9zdAogICAgICBXT1JEUFJFU1NfREJfVVNFUjogJGFkZG9uLmRiLnVzZXIKICAgICAgV09SRFBSRVNTX0RCX1BBU1NXT1JEOiAkYWRkb24uZGIucGFzc3dvcmQKICAgICAgV09SRFBSRVNTX0RCX05BTUU6ICRhZGRvbi5kYi5kYXRhYmFzZQo='
        },
        {
            id: 'ghost',
            title: 'Ghost',
            imageUrl: 'https://user-images.githubusercontent.com/120485/43974508-b64b2fe8-9cd2-11e8-8e58-707254b8817c.png',
            description: "The world's (second) most popular blogging platform",
            template: 'ZnJvbnRlbmRzOgogIC0gbmFtZTogZ2hvc3QKICAgIHR5cGU6IGh0dHAKICAgIGRlZmF1bHQ6IHRydWUKICAgIGRlc3RpbmF0aW9uczoKICAgICAgLSB0YXJnZXQ6IHNlcnZpY2U6Ly9naG9zdDoyMzY4CnNlcnZpY2VzOgogIC0gbmFtZTogZ2hvc3QKICAgIHRhcmdldDogY29udGFpbmVyOi8vZ2hvc3Q6MjM2OApjb250YWluZXJzOgogIC0gbmFtZTogZ2hvc3QKICAgIGltYWdlOiBnaG9zdAogICAgdHlwZTogYzEuc21hbGwKICAgIHZvbHVtZXM6CiAgICAgIC0gbmFtZTogZ2hvc3QtZGF0YQogICAgICAgIHBhdGg6IC92YXIvbGliL2dob3N0L2NvbnRlbnQKICAgICAgICBzdG9yYWdlOiAxMAogICAgaGVhbHRoQ2hlY2s6CiAgICAgIHBhdGg6IC8KICAgICAgcG9ydDogMjM2OAo='
        },
    ];
    const getPage = (children) => (
        <div>
        <Title level={2}>Deploy Application</Title>
        {children}
        </div>
    );
    if(deployed) {
        return getPage((<Redirect to="/applications"/>));
    }
    if(deployState.phase == 'template') {
        return getPage((
            <Row>
                {templates.map(tpl => (
                    <Col key={tpl.id} span={4}>
                        <Row gutter={2} style={{margin: '10px'}}>
                            <Col>
                        <Card onClick={() => {onSelectTemplate(tpl)}} style={{width: '100%',height: '300px'}} cover={<div className="text-center" style={{'paddingTop': '10px', 'height': '180px', 'width': '100%'}}><img style={{'maxHeight': '180px', width: 'auto'}} className="img-fluid"  alt={tpl.id} src={tpl.imageUrl}/></div>} hoverable>
                            <Meta title={tpl.title} description={tpl.description}/>
                        </Card></Col>
                        </Row>
                    </Col>
                ))}
            </Row>
        ));
    } else if(deployState.phase == 'details') {
        const { getFieldDecorator } = props.form;
        return getPage((
            <div>
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={handleSubmit}>
                <Row>
                    <Col >
                    <Form.Item label="Application ID">
                    {getFieldDecorator('appId', {
                        rules: [{ required: true, message: 'Application ID required' }],
                    })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Region">
                    {getFieldDecorator('region', {
                        rules: [{ required: true, message: 'Region required' }],
                    })(
                        <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="Select a Region"
                        optionFilterProp="children"
                        onChange={onSelectRegion}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                      >
                          { regions.map((r,idx) => (
                            <Option key={r.id} value={idx}>{r.id} ({r.city}, {r.country})</Option>
                          ))}
                      </Select>
                    )}
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                    { errorMessage != '' ? (<div><Alert type="error" message={errorMessage}/><br/></div>): ''}
                    <Button type="primary" onClick={() => {setDeployState({...deployState, phase: 'template'})}}><Icon type="left"/> Back</Button>&nbsp;
                    <Button type="primary" htmlType="submit" loading={isCreating}>
                        Deploy
                    </Button>
                    </Form.Item>
                </Col></Row>
                </Form>
            </div>
        ));
    }
};
export default ApplicationsDeployPage;
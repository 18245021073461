import { Alert, Col, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import NitroContext from '../../context';
import { BadRequestError } from '../../service/nitroapi';
import PlanSelector from '../plan-selector';

const PlanUpgradeModal = (props) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [globalState] = useContext(NitroContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);

    useEffect(() => {
        setCurrentPlan(props.provider.getPlan(props.application.planId));
    }, [props.application]);

    const onOk = async () => {
        setLoading(true);
        try {
            await globalState.api.updateManagedApplicationPlan(props.project.id, props.application.applicationId, selectedPlan.id);
            setLoading(false);
            if(props.onFinished) props.onFinished();
        }
        catch(e) {
            console.log(e);
            if(e instanceof BadRequestError) {
                setErrorMessage('Failed to change plan at this time: ' + e.message);
            }
            else {
                setErrorMessage('Failed to change plan at this time.');
            }
            setLoading(false);
        }
    };

    const onSelectPlan = (plan) => {
        setSelectedPlan(plan);
    };

    return (
        <Modal okButtonProps={{disabled: selectedPlan == null || selectedPlan.id == currentPlan.id}} confirmLoading={loading} centered={true} width="80%" title="Change Plan" okText="Change Plan" onOk={onOk} visible={true} onCancel={props.onFinished}>
            <Row>
                <Col>
                    <h3>Select a Billing Plan</h3>
            {errorMessage != null ?
            <Alert type="error" message={errorMessage}/>
            : ''}
                    <p>
                        Choose a plan for your{" "}
                        <strong>{props.provider.name}</strong> site.
                    </p>
                </Col>
            </Row>
            {selectedPlan != null && selectedPlan.id != currentPlan.id ?
            <div><Alert type="info" message={
                <div>
                <p>You have selected the <strong>{selectedPlan.name}</strong> plan. By clicking <strong>Change</strong>, your plan will be changed 
                effective immediately and your site will be modified.<br/>No downtime to your site will occur while your plan is being modified.</p>
                <p><strong>Nitro Powered</strong> uses metered billing, so charges at the new plan rate only start accuring after you modified.</p>
                </div>
            }/><br/></div>
            : ''}
            <PlanSelector provider={props.provider} currentPlan={currentPlan} onSelectPlan={onSelectPlan}/>
        </Modal>
    )
};

export default PlanUpgradeModal;
import React, { useContext, useEffect, useState } from 'react';
import NitroContext from '../../context';
import { Spin, Table } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Loadable from '../../components/loadable/index';
import { Button, Descriptions, Badge, Collapse } from 'antd';
import PaymentDetailsForm from '../../components/payment-details-form';
import {Elements, StripeProvider} from 'react-stripe-elements';
import { config } from '../../constants';
const { Panel } = Collapse;
var moment = require('moment');


const BillingPage = (props) => {
    const [globalState, dispatch] = useContext(NitroContext);
    const [{userInfo}] = useContext(NitroContext);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(new Date());
    const [totalCharges, setTotalCharges] = useState(0.0);
    const [projectData, setProjectData] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(true);
    const [showUpdatePaymentDetails, setShowUpdatePaymentDetails] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [openInvoice, setOpenInvoice] = useState(null);

    const [billData, setBillData] = useState([]);

    useEffect(() => {
        if(userInfo) loadUsage();
        return undefined;
    }, [userInfo]);

    useEffect(() => {
        loadPaymentDetails(globalState.userInfo);
    }, [globalState.userInfo]);

    const loadPaymentDetails = async (userInfo) => {
        try {
            const billingDetails = await globalState.api.getBillingDetails(userInfo.accountId, false);
            setInvoices(billingDetails.invoices.sort((a, b) => {
              return b.startDate - a.startDate;
            }).filter(i => i.paid));
            for(var x = 0; x < billingDetails.invoices.length; x++) {
              if(!billingDetails.invoices[x].paid) {
                setOpenInvoice(billingDetails.invoices[x]);
              }
            }
            setPaymentDetailsLoading(true);
            const resp = await globalState.api.getPaymentDetails(userInfo.accountId);
            setPaymentDetails(resp);
        }
        catch(e) {
            console.log(e);
        }
        setPaymentDetailsLoading(false);
    };

    const loadUsage = async () => {
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        end.setDate(end.getDate() + 1);
        /*end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);*/
        try {
            const resp = await globalState.api.getContainerUsage(userInfo.accountId, start, end);
            setLoading(false);
            setData(resp.usages.map((usage, idx) => {
                return {
                    key: idx,
                    project: usage.projectId,
                    application: usage.applicationId,
                    containerName: usage.containerName,
                    type: usage.containerType + (usage.containerType == 'storage' ? ' / ' + usage.quantity + 'GB' : ''),
                    hours: (usage.runtimeSeconds / 3600).toFixed(2).toString(),
                    charges: "$" + usage.charges.toFixed(4)
                };
            }));

            let projectBreakdown = {};
            resp.usages.forEach(usage => {
                let info = projectBreakdown[usage.projectId] || {charges: 0};
                info.projectId = usage.projectId;
                info.charges += usage.charges;
                projectBreakdown[usage.projectId] = info;
            });
            let items = [];
            let x = 0;
            for(let k in projectBreakdown) {
                items.push({
                    key: x,
                    project: projectBreakdown[k].projectId,
                    charges: "$" + projectBreakdown[k].charges.toFixed(2).toString(),
                });
                x++;
            }
            setProjectData(items);

            setTotalCharges(resp.usages.reduce((t, usage) => {
                return t + usage.charges;
            }, 0.0).toFixed(2));
        }
        catch(e) {
            console.log(e);
        }
    };
    const billColumns = [
        {
          title: 'Date',
          key: 'date',
          responsive: ['xs', 'sm'],
          render: d => {
            return (
              <span>{moment(d.endDate).format('MMMM YYYY')}</span>
            )
          }
        },
        {
          title: 'Balance',
          dataIndex: 'total',
          key: 'balance',
          responsive: ['xs', 'sm'],
          render: t => {
            return "$" + t.toFixed(2);
          }
        },
        {
          title: 'Status',
          key: 'status',
          responsive: ['sm'],
          render: i => {
            return i.paid ? "Paid" + (i.paymentDescription != "" ? (", " + i.paymentDescription) : "") : "Open";
          }
        },
        {
          title: 'Actions',
          key: 'actions',
          responsive: ['sm'],
          render: i => (
            <span>
            {i.invoiceUrl != null && i.invoiceUrl.length > 0 ?
            <a href={i.invoiceUrl}>View</a> : 'N/A'}
            </span>
          )
        },
    ];
    const projectColumns = [
        {
          title: 'Project',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: 'Charges',
          dataIndex: 'charges',
          key: 'charges',
        },
    ]

    const columns = [
        {
          title: 'Product',
          dataIndex: 'product',
          key: 'product',
          responsive: ['xs', 'sm'],
        },
        {
          title: 'Hours',
          dataIndex: 'quantity',
          key: 'quantity',
          responsive: ['sm'],
        },
        {
          title: 'Period',
          key: 'period',
          responsive: ['sm'],
          render: item => {
            return (
              <span>{moment(item.startDate).format('MM/DD')} - {moment(item.endDate).format('MM/DD')}</span>
            );
          }
        },
        {
          title: 'Charges',
          key: 'charges',
          responsive: ['xs', 'sm'],
          render: item => {
            return "$" + (item.amount).toFixed(2);
          }
        },

      ];

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const getDateBillingTZ = (date) => {
      return new Date(date.toLocaleString("en-US", {timeZone: "America/Phoenix"}));
    };

    return (
        <div>
            <h2>Billing and Usage</h2>
            <div className="card card-default">
              <div className="card-header">
            <h3>Payment Details</h3>
            </div>
              <div className="card-body">
            <Loadable loading={paymentDetailsLoading}>
                <div>
                    <Button onClick={() => setShowUpdatePaymentDetails(true)} type="primary">Update Credit Card</Button><br/><br/>
                    { paymentDetails ?
                    <Descriptions size="small" title="" bordered>
                    <Descriptions.Item span={16} label="Name">{paymentDetails.name}</Descriptions.Item>
                    <Descriptions.Item span={16} label="Address">{paymentDetails.addressLine1}{paymentDetails.addressLine2 != '' ? <br/> : ''}{paymentDetails.addressLine2}<br/>{paymentDetails.city}, {paymentDetails.stateProvince}, {paymentDetails.country}</Descriptions.Item>
                    <Descriptions.Item span={16} label="Credit Card"><strong>{paymentDetails.cardIssuer.toUpperCase()}</strong> ****{paymentDetails.last4}<br/><strong>Expires:</strong> {paymentDetails.expMonth}/{paymentDetails.expYear}</Descriptions.Item>
                  </Descriptions>
                : <p>No payment details set. Click above to add your credit card.</p>}
                </div><br/>
            </Loadable>
            </div>
            </div>
            { showUpdatePaymentDetails ?

            <div className="card card-default">
              <div className="card-body">
            <StripeProvider apiKey={config.STRIPE_API_KEY}>
            <Elements>
            <PaymentDetailsForm globalState={globalState}
                onFinished={() => { setShowUpdatePaymentDetails(false); loadPaymentDetails(globalState.userInfo); }} visible={showUpdatePaymentDetails} onCancel={() => setShowUpdatePaymentDetails(false)} />
            </Elements></StripeProvider></div></div> : ''}
            <div className="card card-default">
              <div className="card-header">
                {openInvoice != null ? 
            <h3>Usage for {monthNames[getDateBillingTZ(openInvoice.startDate).getMonth()]} {getDateBillingTZ(openInvoice.startDate).getFullYear()}</h3>
            : <h3>Usage</h3>}
            </div>
            <div className="card-body">
            <Loadable loading={paymentDetailsLoading}>
            <div>
            <Paragraph>Total Charges: <strong>${openInvoice != null ? openInvoice.total.toFixed(2) : "0.00"}</strong></Paragraph>
            <Collapse defaultActiveKey={[]}>
            <Panel header="All Charges">
            <Table className="table" columns={columns} dataSource={openInvoice != null ? openInvoice.lineItems : []}/>
            </Panel>
            </Collapse>
            </div>
            </Loadable>
            </div>
            </div>
            <div className="card card-default">
              <div className="card-header">
              <h3>Bills</h3>
              </div>
              <div className="card-body">
                <Loadable loading={paymentDetailsLoading}>
            <Table className="table" columns={billColumns} dataSource={invoices}/>
            </Loadable>
              </div></div>
        </div>
    );
};
export default BillingPage;
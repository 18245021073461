import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
        "adminEmail": "Admin E-Mail",
        "siteTitle": "Site Title",
        "adminPassword": "Admin Password",
        "adminURL": "Admin URL",
        "wpVersion": "WordPress Version",
        "phpMyAdminPassword": "phpMyAdmin Password",
        "phpMyAdminUser": "phpMyAdmin User",
        "phpMyAdminURL": "phpMyAdmin URL",
    }
  },
  fr: {
    translation: {
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
import { Table, Divider, Tag, Row, Spin, Col, Button } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import NitroContext from '../../context';
import { Link } from 'react-router-dom';
import {
    ReloadOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { ManagedApplication } from '../../service/nitroapi/model';
import ConfirmDeleteModal from '../../components/confirm-delete';

export const SitesPage = (props) => {
    const [globalState, dispatch] = useContext(NitroContext);
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [{ project: selectedProject }] = useContext(NitroContext);
    const [providers, setProviders] = useState([]);
    const [deletingApps, setDeletingApps] = useState({});
    const [confirmDeleteApp, setConfirmDeleteApp] = useState(null);

    useEffect(() => {
        if (globalState.project) reloadApplications();
        return undefined;
    }, [selectedProject]);

    const reloadApplications = async () => {
        setLoading(false);
        try {
            const providers = await globalState.api.getManagedApplicationProviders();
            const applications = (await globalState.api.listManagedApplications(globalState.project.id)).filter(a => !a.isDeleted());
            setApplications(applications);
            setProviders(providers);
            setLoading(false);
            let shouldReload = false;
            applications.forEach(a => {
                if (a.inSomePendingStatus()) shouldReload = true;
            });
            console.log(applications);
            if (shouldReload) {
                setTimeout(reloadApplications, 3000);
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const deleteApplication = async () => {
        try {
            const app = confirmDeleteApp;
            setDeletingApps({ ...deletingApps, [app.applicationId]: true })
            const response = await globalState.api.deleteManagedApplication(selectedProject.id, app.applicationId);
        }
        catch (e) {

        }
        setTimeout(reloadApplications, 3000);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            responsive: ['xs', 'md'],
            render: a => (<Link to={{ pathname: "/sites/" + encodeURIComponent(a.applicationId) }}>{a.applicationName}</Link>),
        },
        {
            title: 'Type',
            dataIndex: 'appType',
            responsive: ['md'],
            key: 'appType',
        },
        {
            title: 'Location',
            responsive: ['md'],
            key: 'location',
            render: a => {
                return (<span>{a.app.region.id} ({a.app.region.city}, {a.app.region.country})</span>);
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            responsive: ['md'],
            key: 'status',
        },
        {
            title: 'URL',
            dataIndex: 'frontends',
            responsive: ['md'],
            key: 'frontends',
        },
        {
            title: 'Action',
            key: 'action',
            responsive: ['md'],
            render: (text, record) => (
                <span>
                    {(record.app.canBeDeleted() && !(deletingApps[record.app.applicationId])) ? <a href="#" onClick={() => { setConfirmDeleteApp(record.app) }}>Delete</a> : <Spin />}
                </span>
            ),
        },
    ];

    const data = applications.map(a => {
        const frontends = (
            <Row key={0}>
                <a target="_blank" href={a.endpoint}>{a.endpoint}</a>
            </Row>
        );
        return {
            key: a.applicationId,
            name: a,
            appType: a.providerId,
            status: (
                <Tag color={a.isDeleting() ? "volcano" : (!a.inSomePendingStatus() ? "green" : "geekblue")}>
                    {a.status.toUpperCase()}
                </Tag>
            ),
            region: (
                <Tag color="green">
                    N/A
                </Tag>
            ),
            frontends: frontends,
            action: a,
            app: a,
        };
    });

    return (
        <div>
            {loading && applications == null ?
                <Row justify="center" type="flex">
                    <Col>
                        <Spin tip="Loading..." />
                    </Col>
                </Row>
                :
                <div>
                    <Row justify="space-between">
                        <Col>
                            <Link to={{ pathname: "/sites/launch" }}><Button icon={<PlusOutlined />} type="primary" size="default">Launch New</Button></Link>
                        </Col>
                        <Col>
                            <Button onClick={reloadApplications} type="primary" icon={<ReloadOutlined />}>Reload</Button>
                        </Col>
                    </Row>
                    <br />
                    <Col>
                        <div className="card card-default">
                            <div className="card-body">
                                <Table bordered className="table" columns={columns} dataSource={data} />
                            </div>
                        </div>
                    </Col></div>}
            {confirmDeleteApp != null ?
                <ConfirmDeleteModal
                    onCancel={() => setConfirmDeleteApp(null)}
                    onDeleteConfirm={deleteApplication}
                    onFinished={async () => {
                        setConfirmDeleteApp(null)
                        await reloadApplications();
                    }}
                    itemName={confirmDeleteApp.applicationName} />
            : ''}
        </div>
    );
};
export { default as SiteLaunchPage } from './launch';
//export default SitesPage;
import React, { useState, useEffect, useContext } from "react";
import { Select, Spin } from "antd";
import NitroContext from "../../context";

const { Option } = Select;

const AccountSelector = (props) => {
  const { width, onAccountSelected } = props;
  const [globalState] = useContext(NitroContext);
  const [accounts, setAccounts] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchProjects() {
      try {
        const resp = await globalState.api.listAccounts();
        setAccounts(resp);
        setLoading(false);
        if (resp.length > 0) {
          setSelectedValue(0);
          if (onAccountSelected) {
            onAccountSelected(resp[0]);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchProjects();
    return undefined;
  }, []);

  const onChange = (value) => {
    if (onAccountSelected) {
      onAccountSelected(accounts[value]);
    }
    setSelectedValue(value);
  };

  const onSearch = (val) => {};

  return (
    <Select
      showSearch
      style={{ width: width != null ? width : null }}
      placeholder="Select an account"
      loading={loading}
      optionFilterProp="children"
      defaultActiveFirstOption={true}
      onChange={onChange}
      value={selectedValue}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {accounts.map((p, idx) => {
        return (
          <Option key={p.id} value={idx}>
            {p.id}
          </Option>
        );
      })}
    </Select>
  );
};
export default AccountSelector;

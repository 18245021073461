import React, {useState, createContext, useContext, useReducer, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";
import NitroContext from '../../context';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SAMLAuthPage = (props) => {
    const query = useQuery();
    const [globalState, dispatch] = useContext(NitroContext);
    const [samlResponse, setSamlResponse] = useState('');
    const [samlUrl, setSamlUrl] = useState('');

    useEffect(() => {
        handleSAML();
    }, []);

    const handleSAML = async () => {
        const resp = await globalState.api.handleSAML(query.get('SAMLRequest'));
        setSamlUrl(resp.redirectUrl);
        setSamlResponse(resp.response);
        document.getElementById('samlForm').submit();
    };

    return (
    <div>
        <p>Don't click anything, redirecting you to support...</p>
        <form id="samlForm" method="post" action={samlUrl}>
            <input type="hidden" name="SAMLResponse" value={samlResponse} />
        </form>
    </div>
    );
};

export default SAMLAuthPage;
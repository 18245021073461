import React, { useState, useRef, useEffect } from 'react';

import { pure } from 'recompose';
import { Grid, GridRows } from '@vx/grid';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { appleStock } from '@vx/mock-data';
import {extent, max} from 'd3-array';
import { scaleTime, scaleLinear, scaleOrdinal } from '@vx/scale';
import { Bar, AreaClosed } from '@vx/shape';
import { Group } from '@vx/group';
import { ParentSize } from '@vx/responsive';
import { LinePath, Line } from '@vx/shape';
import { localPoint } from '@vx/event';
import { Tooltip } from '@vx/tooltip';
import { bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { LegendOrdinal, LegendLabel, LegendItem } from '@vx/legend';
import { ENGINE_METHOD_PKEY_ASN1_METHS } from 'constants';
import { Row, Col } from 'antd';

//const formatDate = timeFormat("%b %d %H:%M, '%y");
const formatDate = (d) => { return d.toString(); }

const defaultFormat = v => v;

const TimeSeriesChart = (props) => {
    const {series: allSeries} = props;
    const [series, setSeries] = useState(allSeries);
    const {
        formatTickY = defaultFormat,
        height: desiredHeight,
        fixedYScale = null,
    } = props;
    //const {series: series} = props;

    useEffect(() => {
            setSeries(allSeries);
            //setExcludedSeriesIndexes([]);
    }, [allSeries]);

    const [excludedSeriesIndexes, setExcludedSeriesIndexes] = useState([]);

    const [tooltipData, setTooltipData] = useState({});
    const [mouseInSVG, setMouseInSVG] = useState(false);

    const bisectDate = bisector(d => props.xMap(d)).left;

    const xScale = scaleTime({
        domain: [
            Math.min(...allSeries.flat().map(value => props.xMap(value))),
            Math.max(...allSeries.flat().map(value => props.xMap(value)))
        ],
    });
    const strokeColors = [
        '#8921e0',
        'red',
        'blue',
        'green'
    ];
    const ordinalColorScale = scaleOrdinal({
        domain: allSeries.map((s,idx) => props.legendMap(idx)),
        range: strokeColors,
    });

    const yScale = scaleLinear({
        domain: fixedYScale ? fixedYScale : [
            Math.max(...allSeries.flat().map(value => props.yMap(value))),
            0
        ],
        nice: true
    });

    const padding = 20;
    const leftOffset = padding * 4;

    const onLegendItemClick = (item) => {
        const excluded = excludedSeriesIndexes.includes(item.index);
        let updatedIndexes;
        if(!excluded) {
            updatedIndexes = excludedSeriesIndexes.concat(item.index);
        } else {
            updatedIndexes = excludedSeriesIndexes.filter(val => val != item.index);
        }

        // All disabled, invert
        /*if(updatedIndexes.length == allSeries.length) {
            updatedIndexes = [];
        }*/

        setExcludedSeriesIndexes(updatedIndexes);
        setSeries(allSeries.filter((s, idx) => {
            return !updatedIndexes.includes(idx);
        }));
    };

    const handleTooltip = ({ event, xMap, yMap, xScale, yScale }) => {
        try {
            if(!mouseInSVG) return;
            if(series.length == 0) return;


            let { x, y } = localPoint(event);
            x -= leftOffset;
            y -= topMargin;

            const x0 = xScale.invert(x);
            const y0 = yScale.invert(y);

            let closest = null;

            series.forEach((s,idx) => {
                const index = bisectDate(s, x0, 1);

                let dataPoint = null;
                if(!s[index] || Math.abs(x0 - xMap(s[index])) > Math.abs(x0 - xMap(s[index - 1]))) {
                    dataPoint = s[index - 1];
                } else {
                    dataPoint = s[index];
                }

                const distance = (Math.abs(y0 - yMap(dataPoint)));

                if(closest == null || distance < closest.distance) {
                    closest = {
                        data: dataPoint,
                        distance: distance
                    };
                }
            });

            if(closest != null) {
                setTooltipData({
                    data: closest.data,
                    left: xScale(xMap(closest.data)),
                    top: yScale(yMap(closest.data))
                });
            }
        }
        catch(e) {
            console.log(e);
        }

    }

    const hideTooltip = () => {
        setTooltipData({});
    };


    const topMargin = 30;
    const bottomMargin = 30;

    const labelProps = { textAnchor: 'middle', fontFamily: 'Arial', fontSize: 14, fill: 'black' };
    const tickLabelProps = (tickValue, index) => ({ textAnchor: 'middle', fontFamily: 'Arial', fontSize: 14, fill: 'black' });
    const tooltipLeft = tooltipData.left || 0;
    const tooltipTop = tooltipData.top || 0;
    return (
        <div style={{background: 'white'}}>
        <div style={{height: desiredHeight}}>
        <ParentSize>
            {({width, height: _height}) => {
                const height = _height - (padding * 2);
                const yMax =  height - topMargin - bottomMargin;
                xScale.range([0, width - (padding * 6)]);
                yScale.range([0, yMax]);
                return (
                    <div style={{background: 'white'}}>
                    { allSeries.length == 0 ?
                    <div style={{width: width, height: height}}>
                    </div>
                    :
                    <React.Fragment>
                    <svg width={width} height={height}

                          onTouchStart={event =>
                                handleTooltip({
                                  event,
                                  xMap: props.xMap,
                                  yMap: props.yMap,
                                  xScale,
                                  yScale,
                                })
                              }
                              onTouchMove={event =>
                                handleTooltip({
                                  event,
                                  xMap: props.xMap,
                                  yMap: props.yMap,
                                  xScale,
                                  yScale,
                                })
                              }
                              onMouseMove={event =>
                                handleTooltip({
                                  event,
                                  xMap: props.xMap,
                                  yMap: props.yMap,
                                  xScale,
                                  yScale,
                                })
                              }
                    onMouseLeave={event => hideTooltip() && setMouseInSVG(false)}
                    onMouseEnter={() => { setMouseInSVG(true)}}
                    >
                        <rect x={0} y={0} width={width} height={height} fill="#ffffff" rx={16}
                        />
                        <Group top={topMargin} left={leftOffset}
                        >
                            <GridRows scale={yScale} width={width - (padding * 6)} height={height - topMargin - bottomMargin} />
                            <AxisBottom tickLabelProps={tickLabelProps} numTicks={Math.round(width / 200) + 1} top={height - topMargin - bottomMargin} labelProps={labelProps} scale={xScale}/>
                            <AxisLeft hideTicks={true} tickFormat={formatTickY} tickLabelProps={tickLabelProps} numTicks={Math.round(height / 250) + 1} labelProps={labelProps} left={-20} label={props.yAxisLabel} scale={yScale} hideAxisLine={true}/>
                            { allSeries.map((s, idx) => {
                                if(!excludedSeriesIndexes.includes(idx)) {
                                    return (<LinePath key={idx} data={s} x={d => xScale(props.xMap(d))} y={d => yScale(props.yMap(d))}
                                            stroke={ordinalColorScale(props.legendMap(idx))}
                                            strokeWidth={2}/>);
                                } else { return (<React.Fragment key={idx}/>); }
                            })}
                            { tooltipData && tooltipData.data && (
                            <g>
                            <Line
                                from={{ x: tooltipLeft, y: 0 }}
                                to={{ x: tooltipLeft, y: yMax }}
                                stroke="rgba(92, 119, 235, 1.000)"
                                strokeWidth={2}
                                style={{ pointerEvents: 'none' }}
                                strokeDasharray="2,2"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop + 1}
                                r={4}
                                fill="black"
                                fillOpacity={0.1}
                                stroke="black"
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                style={{ pointerEvents: 'none' }}
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop}
                                r={4}
                                fill="rgba(92, 119, 235, 1.000)"
                                stroke="white"
                                strokeWidth={2}
                                style={{ pointerEvents: 'none' }}
                            />
                            </g>)}
                        </Group>
                    </svg>

                    { tooltipData && tooltipData.data && (
                        <div>
                            <Tooltip
                            top={tooltipTop >= (yMax - 32) ? yMax - 32 : tooltipTop + 64}
                            left={tooltipLeft < (width / 2) ? tooltipLeft + 100 : tooltipLeft}
                            style={{
                            backgroundColor: 'rgba(92, 119, 235, 1.000)',
                            color: 'white'
                            }}
                        >
                            {props.formatTooltipY(tooltipData.data)}
                        </Tooltip>
                        <Tooltip
                            top={yMax + 32}
                            left={tooltipLeft < (width / 2) ? tooltipLeft + 175 : tooltipLeft}
                            style={{
                            transform: 'translateX(-50%)'
                            }}
                        >
                            {props.formatTooltipX(tooltipData.data)}
                        </Tooltip>
                        </div>
                    )}
                </React.Fragment>}
                </div>);
            }}
        </ParentSize></div>
                            <LegendOrdinal scale={ordinalColorScale} labelFormat={(label, i) => `${label}`}>
                                {labels => {
                                    return (
                                    <Row style={{ marginTop: -20, 'paddingLeft': '5px', paddingRight: '5px', paddingBottom: '5px'}}>
                                        {labels.map((label, i) => {
                                        const size = 15;
                                        const opacity = excludedSeriesIndexes.includes(i) ? 0.5 : 1.0;
                                        return (
                                            <Col key={`legend-${label}-${i}`} span={12}>
                                            <div style={{opacity: opacity}} key={`legend-${label}-${i}`}>
                                            <LegendItem
                                            margin={'0 5px'}
                                            onClick={() => { onLegendItemClick(label) }}
                                            >
                                            <svg width={size} height={size}>
                                                <rect fill={label.value} width={size} height={size} />
                                            </svg>
                                            <LegendLabel  align={'left'} margin={'0 0 0 4px'}>
                                                <a href="#">{label.text}</a>
                                            </LegendLabel>
                                            </LegendItem>
                                            </div>
                                            </Col>
                                        );
                                        })}
                                    </Row>
                                    );
                                }}
                            </LegendOrdinal>
        </div>
    )
};
export default TimeSeriesChart;
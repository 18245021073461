import { Input, Radio } from "antd";
import { Form } from "antd";
import React, { useContext, useState } from "react";
import NitroContext from "../../context";
import InputAPIValidator from "../input-api-validator";

const DomainSelector = (props) => {
  const [useCustomDomain, setUseCustomDomain] = useState(false);
  const [globalState] = useContext(NitroContext);
  const [path, setPath] = useState("/");

  const onUseCustomDomainChange = (e) => {
    setUseCustomDomain(e.target.value);
  };

  const domainValidator = async (value) => {
    var domain;
    if (useCustomDomain) {
      domain = value;
    } else {
      domain = value + ".nitroapp.io";
    }
    return await globalState.api.validateManagedApplicationDomain(domain, path);
  };

  return (
    <React.Fragment>
      <Form.Item
        name={props.useCustomDomainFieldName}
        label="Domain Type"
        initialValue={false}
      >
        <Radio.Group onChange={onUseCustomDomainChange}>
          <Radio value={false}>nitroapp.io URL</Radio>
          <Radio value={true}>Custom Domain</Radio>
        </Radio.Group>
      </Form.Item>
      {useCustomDomain ? (
        <React.Fragment>
          <Form.Item
            name={props.customDomainFieldName}
            label="Domain Name"
            rules={[
              { required: true, message: "Domain name is required" },
              {
                pattern:
                  "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$",
                message: "Invalid domain name format",
              },
            ]}
          >
            <div>
              <InputAPIValidator
                additional={path}
                onValidate={domainValidator}
                maxLength={128}
                style={{ width: "300px" }}
              />
              <p>
                After your site is created, you will need to verify your domain
                ownership.
              </p>
            </div>
          </Form.Item>
          <Form.Item
            name={props.customDomainPathFieldName}
            style={{display: 'none'}}
            label="Path"
            onChange={(e) => {
              setPath(e.target.value);
            }}
            labelCol={{ offset: 1 }}
            initialValue="/"
            rules={[
              { required: true, message: "Path is required" },
              {
                pattern: "/[A-Za-z0-9_-]*$",
                message: "Invalid URL path",
              },
            ]}
          >
            <Input maxLength={32} style={{ width: "75px" }} />
          </Form.Item>
        </React.Fragment>
      ) : (
        <Form.Item
          name={props.subdomainFieldName}
          label="Domain Name"
          rules={[
            { required: true, message: "Domain name is required" },
            {
              pattern: "^(?!-)[a-zA-Z0-9-]{1,63}$",
              message:
                "Invalid domain format, only letters, numbers, and dashes.",
            },
          ]}
        >
          <InputAPIValidator
            onValidate={domainValidator}
            addonAfter=".nitroapp.io"
            maxLength={32}
            style={{ textAlign: "right", width: "300px" }}
          />
        </Form.Item>
      )}
    </React.Fragment>
  );
};

export default DomainSelector;

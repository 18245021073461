import {
  Button,
  Descriptions,
  Divider,
  List,
  Space,
  Spin,
  Table,
  Tag,
  Statistic,
  Card,
  Typography,
  Row,
  Col,
  Progress,
} from "antd";
import React, { useEffect, useContext, useState } from "react";
import { Collapse } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import Loadable from "../../components/loadable";
import NitroContext from "../../context";
import { NotFoundError } from "../../service/nitroapi";
import { useTranslation } from "react-i18next";
import { DomainNameType } from "../../service/nitroapi/model";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import ChangeDomain from "../../components/change-domain";
import CreateSnapshotModal from "../../components/create-snapshot";
import ConfirmDeleteModal from "../../components/confirm-delete";
import PlanUpgradeModal from "../../components/plan-upgrade";

const { Panel } = Collapse;
var moment = require("moment");

const ViewSitePage = (props) => {
  const [globalState, dispatch] = useContext(NitroContext);
  const [{ project: selectedProject }] = useContext(NitroContext);
  let { id } = useParams();
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);

  const [showUpgrade, setShowUpgrade] = useState(false);
  const [application, setApplication] = useState(null);
  const [provider, setProvider] = useState(null);
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState([]);
  const [modifyButtonsEnabled, setModifyButtonsEnabled] = useState(false);
  const [changeDomainButtonEnabled, setChangeDomainButtonEnabled] =
    useState(false);
  const [snapshots, setSnapshots] = useState([]);
  const [showChangeDomainDialog, setShowChangeDomainDialog] = useState(false);

  const [snapshotFormVisible, setSnapshotFormVisible] = useState(false);
  const [snapshotDelete, setSnapshotDelete] = useState(null);

  useEffect(() => {
    if (globalState.project) {
      loadApplication(id);
    }
  }, [selectedProject]);

  useEffect(() => {
    maybeReloadApplication();
    loadSnapshots();
  }, [application]);

  const loadSnapshots = async () => {
    if (!globalState.project || !application) return;
    try {
      const snapshots = await globalState.api.listManagedApplicationSnapshots(
        globalState.project.id,
        application.applicationId
      );
      setSnapshots(snapshots.filter(s => {
        return s.isAutomatedSnapshot() || s.isManualSnapshot();
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const maybeReloadApplication = async () => {
    if (application == null) return;

    var autoReload = false;

    if (application.domainVerification) {
      if (
        !application.domainVerification.ingressVerified ||
        !application.domainVerification.verified
      ) {
        autoReload = true;
      }
    }
    if (application.status != "ACTIVE") {
      autoReload = true;
    }

    if (!application.inSomePendingStatus()) {
      setModifyButtonsEnabled(true);
    }

    if (autoReload) {
      setTimeout(async () => {
        await loadApplication(application.applicationId);
      }, 2000);
    }
  };

  const deleteSnapshot = async () => {
    await globalState.api.deleteManagedApplicationSnapshot(globalState.project.id, application.applicationId, snapshotDelete.id);
  };

  const onFinishedChangeDomain = async () => {
    setShowChangeDomainDialog(false);
    const appId = application.applicationId;
    setApplication(null);
    await loadApplication(appId);
  };

  const loadApplication = async (id) => {
    try {
      const app = await globalState.api.getManagedApplication(
        globalState.project.id,
        id
      );
      const provider = await globalState.api.getManagedApplicationProvider(
        app.providerId
      );
      setProvider(provider);
      setApplication(app);
      setErrorMessage(null);

      setChangeDomainButtonEnabled(
        !app.inSomePendingStatus() || app.isUpdating()
      );
      if (
        app.domainVerification &&
        !app.domainVerification.ingressVerified
      ) {
        setActiveKey('domain_settings');
      }
    } catch (e) {
      if (e instanceof NotFoundError) {
        setErrorMessage("Site Not Found");
      } else {
        console.log(e);
        setErrorMessage("Unable to load site at this time.");
      }
    }
  };

  const getDomainSettingsTitle = () => {
    if (
      application.domainVerification &&
      application.domainVerification.ingressVerified
    ) {
      return (
        <span>
          Domain Settings - <strong>Success</strong>{" "}
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </span>
      );
    } else {
      return (
        <span>
          Domain Settings - <strong>Pending</strong>{" "}
          <CloseCircleTwoTone twoToneColor="#eb2f96" />
        </span>
      );
    }
  };

  const onClickChangeDomain = async () => {
    setShowChangeDomainDialog(true);
  };

  const showSnapshotDeleteConfirm = async (job) => {
    setSnapshotDelete(job);
  };

  const onClickClone = async () => {
    try {
      setErrorMessage(null);
      setModifyButtonsEnabled(false);
      const snapshotId = await globalState.api.snapshotManagedApplication(
        selectedProject.id,
        application.applicationId,
        '',
        true
      );
      history.push(
        "/sites/launch?providerId=" +
          encodeURIComponent(application.providerId) +
          "&snapshotId=" +
          encodeURIComponent(snapshotId)
      );
    } catch (e) {
      console.log("failed to snapshot: " + e);
      setErrorMessage("Failed to clone at this time, please try again later.");
      setModifyButtonsEnabled(true);
    }
  };

  const getVerificationTitle = () => {
    if (application.domainVerification.verified) {
      return (
        <span>
          Domain Ownership Verification - <strong>Success</strong>{" "}
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </span>
      );
    } else {
      return (
        <span>
          Domain Ownership Verification - <strong>Pending</strong>{" "}
          <CloseCircleTwoTone twoToneColor="#eb2f96" />
        </span>
      );
    }
  };

  const changeActiveKey = (e) => {
    setActiveKey(e);
  };

  const getDomainRecordA = () => {
    return (
      application.domainName +
      "       A " +
      application.domainVerification.ingressTargetA
    );
  };
  const getDomainRecordCNAME = () => {
    return (
      application.domainName +
      "       CNAME " +
      application.domainVerification.ingressTargetCNAME
    );
  };

  const getPlan = () => {
    const plan = provider.getPlan(application.planId);
    return (
      <span>
        <strong>{plan.name}</strong> (${plan.priceBilledMonthly}/month)
      </span>
    );
  };

  const getPlanDiskUsage = () => {
    const plan = provider.getPlan(application.planId);
    return 0;
  };

  const snapshotsColumns = [
    {
      title: "Date",
      dataIndex: "finishedAt",
      responsive: ['xs', 'sm'],
      render: (text, job) =>
        moment(job.finishedAt == null ? job.createdAt : job.finishedAt).format("MMMM Do YYYY, h:mm A"),
    },
    {
      title: "Name",
      responsive: ['xs', 'sm'],
      render: (job) => {
        if(job.isAutomatedSnapshot()) {
          return "Automated System Snapshot";
        }
        else {
          return job.name && job.name.length > 0 ? job.name : "Untitled";
        }
      }
    },
    {
      title: "Type",
      responsive: ['sm'],
      render: (job) => {
        return job.snapshotType();
      }
    },
    {
      title: "Storage Usage",
      responsive: ['sm'],
      render: (job) => {
        if(job.isManualSnapshot() && job.isFinished()) {
          return (job.storageUsageBytes / (1024 * 1024)).toFixed(2) + "MB";
        }
        else {
          return "N/A";
        }
      }
    },
    {
      title: "Status",
      responsive: ['sm'],
      render: (job) => {
        console.log(job);
        return job.statusName();
      }
    },
    {
      title: "Action",
      responsive: ['sm'],
      key: "action",
      render: (text, job) => (
        <Space size="middle">
          {job.isFinished() ? (
            <div>
            <Link
              to={
                "/sites/launch?providerId=" +
                encodeURIComponent(application.providerId) +
                "&snapshotId=" +
                encodeURIComponent(job.id)
              }
            >
            <Button type="primary" size="small">Restore</Button>
            </Link>
            &nbsp;
            {!job.isAutomatedSnapshot() ? <Button onClick={() => showSnapshotDeleteConfirm(job)} type="danger" size="small">Delete</Button> : ''}
            </div>
          ) : (
            "N/A"
          )}
        </Space>
      ),
    },
  ];

  const getStorageTextColor = () => {
    const usage = application.totalDiskUsageBytes / application.allowedDiskUsageBytes;
    if(usage < 0.9) {
      return 'black';
    }
    else {
      return 'red';
    }
  };

  const getStorageProgressColor = () => {
    const usage = application.totalDiskUsageBytes / application.allowedDiskUsageBytes;
    if(usage < 0.75) {
      return 'blue';
    }
    else if(usage < 0.9) {
      return 'yellow';
    }
    else {
      return 'red';
    }
  };

  /*const snapshotsData = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];*/

  return (
    <div>
      {errorMessage != null ? (
        <p>{errorMessage}</p>
      ) : (
        <Loadable loading={application == null}>
          {application != null ? (
            <div>
              <h3>{application.applicationName}</h3>
              <Button
                type="primary"
                onClick={onClickClone}
                disabled={!modifyButtonsEnabled}
              >
                Clone
              </Button>{" "}
              &nbsp;
              <Button
                type="primary"
                onClick={onClickChangeDomain}
                disabled={!changeDomainButtonEnabled}
              >
                Change Domain
              </Button>
              <br />
              <br />
              <div className="card">
                <Descriptions
                  style={{ backgroundColor: "white", padding: "20px" }}
                >
                  <Descriptions.Item label="Site Type">
                    <Tag color="blue">{application.providerId}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Domain">
                    <a href={application.endpoint} target="_blank">
                      {application.domainName}
                      {application.sitePath == "/" ? "" : application.sitePath}
                    </a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <strong>{application.status}</strong> &nbsp;&nbsp;
                    {application.inSomePendingStatus() ? (
                      <Spin size="small" />
                    ) : (
                      ""
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Plan">
                    {getPlan()} {!globalState.userInfo.isTrial() && !application.inSomePendingStatus() ? <span>&nbsp; <Link to="#" onClick={() => setShowUpgrade(true)}>[Change]</Link></span> : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Subscription Start">
                    {moment(application.subscriptionStart).format(
                      "MMMM Do YYYY"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Automatic Updates">
                    <strong>{application.updateStrategy.description}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Location">
                    <strong>{application.region.id} ({application.region.city}, {application.region.country})</strong>
                  </Descriptions.Item>
                </Descriptions>
              </div>
              <Collapse activeKey={activeKey} onChange={changeActiveKey}>
                {application.domainType == DomainNameType.CUSTOM ? (
                  <Panel
                    header={getDomainSettingsTitle()}
                    key="domain_settings"
                  >
                    <p>
                      To finish setting up your site, make sure the domain{" "}
                      <strong>{application.domainName}</strong> has it's DNS
                      setup properly.
                    </p>
                    <p>
                      Add the following CNAME record to your domain through your DNS provider. 
                    </p>
                    <pre>{getDomainRecordCNAME()}</pre>

                  {application.domainVerification != null && application.domainVerification.ingressTargetA != null && application.domainVerification.ingressTargetA.length > 0 ?
                  <div>
                    <p>
                      <strong>NOTE:</strong> If you are configuring a root domain and your provider does not support CNAME flattening, you may add the following record instead:
                    </p>
                    <pre>{getDomainRecordA()}</pre>
                    </div>
                    : ''}

                {application.domainVerification != null ? (
                  <div>
                    <h4>CloudFlare</h4>
                    <p>If you are using <strong>CloudFlare</strong>, you will need to add an additional record to your domain. For more information, <a href="https://nitropowered.freshdesk.com/support/solutions/articles/73000176725-site-dns-settings">click here.</a></p>
                      <pre>
                        _nitropowered.{application.domainVerification.domain}    TXT{" "}
                        {application.domainVerification.cnameTarget}
                      </pre>
                  </div>
                ) : (
                  ""
                )}
                  </Panel>
                ) : (
                  ""
                )}
                {application.domainVerification != null ? (
                  <Panel style={{display: 'none'}} header={getVerificationTitle()} key="verify">
                    <div>
                      <p>
                        To verify ownership of the domain, create the following
                        record with your DNS provider:
                      </p>
                      <pre>
                        {application.domainVerification.domain} TXT{" "}
                        {application.domainVerification.cnameTarget}
                      </pre>
                    </div>
                  </Panel>
                ) : (
                  ""
                )}
                <Panel header="Installation Details" key="install_details">
                  <List
                    bordered
                    dataSource={[].concat(
                      application.configs,
                      application.state
                    )}
                    renderItem={(item) => (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          title={t(item.id)}
                          description={
                            item.value.startsWith &&
                            (item.value.startsWith("http://") ||
                              item.value.startsWith("https://")) ? (
                              <a target="_blank" href={item.value}>
                                {item.value}
                              </a>
                            ) : item.name != "" ? (
                              item.name
                            ) : (
                              item.value
                            )
                          }
                        ></List.Item.Meta>
                      </List.Item>
                    )}
                  />
                </Panel>
                {application.sftpDetails ? (
                  <Panel
                    header="SFTP Connection Information"
                    key="sftp_details"
                  >
                    <strong>SFTP Server: </strong>{" "}
                    {application.sftpDetails.host}
                    <br />
                    <strong>SFTP Port: </strong> {application.sftpDetails.port}
                    <br />
                    <strong>SFTP Username: </strong>{" "}
                    {application.sftpDetails.user}
                    <br />
                    <strong>SFTP Password: </strong>{" "}
                    {application.sftpDetails.password}
                  </Panel>
                ) : (
                  ""
                )}
              </Collapse>
            </div>
          ) : (
            ""
          )}
          {showUpgrade ? 
            <PlanUpgradeModal project={selectedProject} application={application} provider={provider} onFinished={async () => {
              setShowUpgrade(false);
              loadApplication(application.applicationId);
            }}/> : ''}
          {snapshotDelete != null ?
          <ConfirmDeleteModal
            onCancel={() => setSnapshotDelete(null)}
            onDeleteConfirm={deleteSnapshot}
            onFinished={async () => {
              setSnapshotDelete(null);
              setSnapshots(null)
              await loadSnapshots();
            }}
            itemName={'the snapshot on ' + application.applicationName + ' from ' + moment(snapshotDelete.startedAt).format('MMMM Do YYYY, h:mm:ss a')}/>
          : ''}
          {showChangeDomainDialog ? (
            <ChangeDomain
              project={selectedProject}
              application={application}
              onFinished={onFinishedChangeDomain}
              onCancel={() => setShowChangeDomainDialog(false)}
              visible={true}
            />
          ) : (
            ""
          )}
          {snapshotFormVisible ? (
            <CreateSnapshotModal project={selectedProject} application={application}
              onFinished={async () => {
                setSnapshotFormVisible(false);
                setSnapshots(null);
                await loadSnapshots();
              }}
            />
          ): ("")}
          <br />
          {application != null ? 
          <div>
          <h4>Plan Usage</h4>
          <Row gutter={16}>
            <Col span={4}>
              <Card>
                <Statistic title="Current Disk Usage" formatter={(val) => val.toFixed(3)} suffix="GB" value={application.totalDiskUsageBytes / (1024 * 1024 * 1024)}/>
                <Col span={23}>
                <Progress strokeColor={getStorageProgressColor()} format={(val) => (<div style={{color: getStorageTextColor()}}>{val.toFixed(2)}%</div>)} percent={application.totalDiskUsageBytes == 0 ? 0 : (application.totalDiskUsageBytes / application.allowedDiskUsageBytes) * 100}/>
                </Col>
                <Statistic title="Allowed Disk Usage" suffix="GB" value={application.allowedDiskUsageBytes / (1024 * 1024 * 1024)}/>
              </Card>
            </Col>
          </Row>
          </div>
          : ''}
          <h4>Snapshots</h4>
          <Button
            type="primary"
            disabled={!modifyButtonsEnabled}
            onClick={async () => {
              setSnapshotFormVisible(true);
              await loadSnapshots();
            }}
          >
            New Snapshot
          </Button>&nbsp;
          <Button type="primary" onClick={() => loadSnapshots()}>Reload</Button>
          <br/><br/>
          <div className="card">
            <Table
              columns={snapshotsColumns}
              dataSource={snapshots}
              rowKey="id"
            />
          </div>
        </Loadable>
      )}
    </div>
  );
};

export default ViewSitePage;

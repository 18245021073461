import React, { useEffect } from 'react';
import { Select } from 'antd';
const { Option } = Select;

const TimeRangePicker = (props) => {
    const handleChange = (idx) => {
        const o = options[idx];
        const endTime = new Date();
        const startTime = new Date(endTime);
        startTime.setSeconds(startTime.getSeconds() - o[2]);
        if(props.onSelect) props.onSelect(startTime, endTime);
    };
    const options = [
        ['lastHour', 'Last Hour', 3600],
        ['last3Hours', 'Last 3 Hours', 3600*3],
        ['lastDay', 'Last 24 Hours', 3600*24],
        ['last7Day', 'Last 7 Days', 3600*24*7],
    ];

    useEffect(() => {
        const endTime = new Date();
        const startTime = new Date(endTime);
        startTime.setSeconds(startTime.getSeconds() - options[0][2]);
        if(props.onSelect) props.onSelect(startTime, endTime);
    }, []);

    return (
        <React.Fragment>
            <Select defaultValue={0} style={{ width: 160 }} onChange={handleChange}>
                {options.map((o,idx) => (
                 <Option key={o[0]} value={idx}>{o[1]}</Option>
                ))}
            </Select>
        </React.Fragment>
    );
};
export default TimeRangePicker;
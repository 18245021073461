import * as md5 from "md5";

export class User {
  firstName = "";
  lastName = "";
  email = "";
  accountId = "";
  accountStatus = "";

  constructor(props) {
    Object.assign(this, props);
  }

  getProfileImageURL() {
    const hash = md5(this.email.toLowerCase().trim());
    return "https://www.gravatar.com/avatar/" + hash;
  }

  static fromAPI(obj) {
    return new User({
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      accountId: obj.accountId,
      accountStatus: obj.accountStatus,
    });
  }

  isTrial() {
    return this.accountStatus == "trial";
  }
}

export class Account {
  id = "";
  createdAt = null;

  constructor(props) {
    Object.assign(this, props);
  }

  static fromAPI(obj) {
    return new Account({
      id: obj.id,
      createdAt: new Date(obj.createdAt),
    });
  }
}

export class Project {
  id = "";
  accountId = "";
  createdAt = null;
  status = "";

  static fromAPI(obj) {
    const p = new Project();
    p.id = obj.id;
    p.accountId = obj.accountId;
    p.status = obj.status;
    p.createdAt = new Date(obj.createdAt);
    return p;
  }

  isAvailable() {
    return this.status.toLowerCase() == "available";
  }

  inSomePendingStatus() {
    return this.status != "AVAILABLE" && this.status != "DELETED" && this.status != "UPDATING";
  }

  canBeDeleted() {
    return this.status == "AVAILABLE";
  }

  isDeleting() {
    return this.status == "DELETING";
  }
}

export class CreateUser {
  firstName = "";
  lastName = "";
  emailAddress = "";
  password = "";
  inviteCode = "";
}

export class ApplicationFrontend {
  name = "";
  endpoint = "";
  domainCNAME = "";

  static fromAPI(obj) {
    const f = new ApplicationFrontend();
    f.name = obj.name;
    f.endpoint = obj.endpoint;
    f.domainCNAME = obj.domainCNAME;
    return f;
  }
}

export class ApplicationState {
  frontends = [];

  static fromAPI(obj) {
    const s = new ApplicationState();
    if (obj.frontends) {
      s.frontends = obj.frontends.map(ApplicationFrontend.fromAPI);
    }
    return s;
  }
}

export class Application {
  id = "";
  state = null;
  status = "";
  region = "";

  static fromAPI(obj) {
    const p = new Application();
    p.id = obj.id;
    p.state = ApplicationState.fromAPI(obj.state);
    p.status = obj.status;
    p.region = obj.region;
    return p;
  }

  inSomePendingStatus() {
    return this.status != "deployed";
  }

  canBeDeleted() {
    return this.status == "deployed";
  }

  isDeleting() {
    return this.status == "deleting";
  }
}

export class ContainerUsage {
  applicationId = "";
  containerName = "";
  containerType = "";
  projectId = "";
  runtimeSeconds = 0;
  charges = 0.0;
  quantity = 0;

  static fromAPI(obj) {
    const usage = new ContainerUsage();
    usage.projectId = obj.projectId;
    usage.applicationId = obj.applicationId;
    usage.containerName = obj.containerName;
    usage.containerType = obj.containerType;
    usage.runtimeSeconds = parseInt(obj.runtimeSeconds);
    usage.charges = obj.charges;
    usage.quantity = obj.quantity;
    return usage;
  }
}

export class ContainerUsages {
  usages = [];

  static fromAPI(obj) {
    const usages = new ContainerUsages();
    usages.usages = obj.containerUsages.map(ContainerUsage.fromAPI);
    return usages;
  }
}

export class MetricSample {
  longValue = 0;
  floatValue = 0.0;
  timestamp = null;

  static fromAPI(obj) {
    const sample = new MetricSample();
    sample.longValue = parseInt(obj.longValue);
    sample.floatValue = obj.floatValue;
    sample.timestamp = new Date(obj.timestamp);
    return sample;
  }
}

export class MetricData {
  metricName = "";
  resourceId = "";
  applicationId = "";
  samples = [];

  static fromAPI(obj) {
    const data = new MetricData();
    data.metricName = obj.metricName;
    data.resourceId = obj.resourceId;
    data.applicationId = obj.applicationId;
    data.samples = obj.samples
      .map(MetricSample.fromAPI)
      .sort((a, b) => a.timestamp - b.timestamp);
    return data;
  }
}

export class MetricDataList {
  metricData = [];

  static fromAPI(obj) {
    const dataList = new MetricDataList();
    dataList.metricData = obj.metricData.map(MetricData.fromAPI);
    return dataList;
  }
}

export class Region {
  id = "";
  city = "";
  country = "";

  toString() {
    return this.id + " (" + this.city + ", " + this.country + ")";
  }

  static fromAPI(obj) {
    const region = new Region();
    region.id = obj.id;
    region.city = obj.city;
    region.country = obj.country;
    return region;
  }
}

export class PaymentDetails {
  name = "";
  addressLine1 = "";
  addressLine2 = "";
  city = "";
  stateProvince = "";
  country = "";
  last4 = "";
  cardIssuer = "";
  expMonth = 0;
  expYear = 0;

  static fromAPI(obj) {
    const details = new PaymentDetails();
    details.name = obj.name;
    details.addressLine1 = obj.addressLine1;
    details.addressLine2 = obj.addressLine2;
    details.city = obj.city;
    details.stateProvince = obj.stateProvince;
    details.country = obj.country;
    details.last4 = obj.last4;
    details.cardIssuer = obj.cardIssuer;
    details.expMonth = obj.expMonth;
    details.expYear = obj.expYear;
    return details;
  }
}

export class ProjectEvents {
  events = [];
  static fromAPI(obj) {
    const events = new ProjectEvents();
    events.events = obj.events.map(ProjectEvent.fromAPI);
    return events;
  }
}

export class ProjectEvent {
  applicationId = "";
  contextId = "";
  type = "";
  message = "";
  createdAt = "";

  getType() {
    if (this.type == "application_deploy_success") {
      return "Deployment Success";
    }
    return this.type;
  }

  static fromAPI(obj) {
    const event = new ProjectEvent();
    event.applicationId = obj.applicationId;
    event.contextId = obj.contextId;
    event.type = obj.type;
    event.message = obj.message;
    event.createdAt = new Date(obj.createdAt);
    return event;
  }
}

export const BillingType = {
  ANNUALLY: "ANNUALLY",
  MONTHLY: "MONTHLY",
};

export const ManagedApplicationFieldTypes = {
  STRING: "STRING",
  LONG: "LONG",
  BYTES: "BYTES",
  BOOL: "BOOL",
  FLOAT: "FLOAT",
  MULTI_STRING: "MULTI_STRING",
};

export class MultiStringOption {
  id = "";
  name = "";

  static fromAPI(obj) {
    const opt = new MultiStringOption();
    opt.id = obj.id;
    opt.name = obj.name;
    return opt;
  }
}

export class ManagedApplicationFieldValue {
  id = "";
  type = null;
  valueBool = false;
  valueString = "";
  valueLong = 0;
  valueFloat = 0.0;
  name = "";

  static newBool(id, value) {
    const fieldValue = new ManagedApplicationFieldValue();
    fieldValue.id = id;
    fieldValue.type = ManagedApplicationFieldTypes.BOOL;
    fieldValue.valueBool = value;
    return fieldValue;
  }

  static newFloat(id, value) {
    const fieldValue = new ManagedApplicationFieldValue();
    fieldValue.id = id;
    fieldValue.type = ManagedApplicationFieldTypes.FLOAT;
    fieldValue.valueFloat = value;
    return fieldValue;
  }

  static newLong(id, value) {
    const fieldValue = new ManagedApplicationFieldValue();
    fieldValue.id = id;
    fieldValue.type = ManagedApplicationFieldTypes.LONG;
    fieldValue.valueLong = value;
    return fieldValue;
  }

  static newMultiString(id, value) {
    const fieldValue = new ManagedApplicationFieldValue();
    fieldValue.id = id;
    fieldValue.type = ManagedApplicationFieldTypes.MULTI_STRING;
    fieldValue.valueString = value;
    return fieldValue;
  }

  static newString(id, value) {
    const fieldValue = new ManagedApplicationFieldValue();
    fieldValue.id = id;
    fieldValue.type = ManagedApplicationFieldTypes.STRING;
    fieldValue.valueString = value;
    return fieldValue;
  }

  static buildForFieldWithValue(field, value) {
    if (field.type == ManagedApplicationFieldTypes.BOOL) {
      return this.newBool(field.id, value);
    } else if (field.type == ManagedApplicationFieldTypes.LONG) {
      return this.newLong(field.id, value);
    } else if (field.type == ManagedApplicationFieldTypes.FLOAT) {
      return this.newFloat(field.id, value);
    } else if (field.type == ManagedApplicationFieldTypes.STRING) {
      return this.newString(field.id, value);
    } else if (field.type == ManagedApplicationFieldTypes.MULTI_STRING) {
      return this.newMultiString(field.id, value);
    }
  }

  get value() {
    if (this.type == ManagedApplicationFieldTypes.BOOL) {
      return this.valueBool;
    } else if (this.type == ManagedApplicationFieldTypes.STRING) {
      return this.valueString;
    } else if (this.type == ManagedApplicationFieldTypes.LONG) {
      return this.valueLong;
    } else if (this.type == ManagedApplicationFieldTypes.FLOAT) {
      return this.valueFloat;
    } else if (this.type == ManagedApplicationFieldTypes.MULTI_STRING) {
      return this.valueString;
    }
  }

  set value(val) {
    if (this.type == ManagedApplicationFieldTypes.BOOL) {
      this.valueBool = val;
    } else if (this.type == ManagedApplicationFieldTypes.STRING) {
      this.valueString = val;
    } else if (this.type == ManagedApplicationFieldTypes.LONG) {
      this.valueLong = val;
    } else if (this.type == ManagedApplicationFieldTypes.FLOAT) {
      this.valueFloat = val;
    } else if (this.type == ManagedApplicationFieldTypes.MULTI_STRING) {
      this.valueString = val;
    }
  }

  toJSON() {
    var obj = {
      id: this.id,
      type: this.type,
    };
    if (this.type == ManagedApplicationFieldTypes.BOOL) {
      obj.valueBool = this.valueBool;
    } else if (this.type == ManagedApplicationFieldTypes.STRING) {
      obj.valueString = this.valueString;
    } else if (this.type == ManagedApplicationFieldTypes.LONG) {
      obj.valueLong = this.valueLong;
    } else if (this.type == ManagedApplicationFieldTypes.FLOAT) {
      obj.valueFloat = this.valueFloat;
    } else if (this.type == ManagedApplicationFieldTypes.MULTI_STRING) {
      obj.valueString = this.valueString;
    }

    return obj;
  }

  static fromAPI(obj) {
    const field = new ManagedApplicationFieldValue();
    field.id = obj.id;
    field.name = obj.name;
    field.description = obj.description;
    field.required = obj.required;
    field.type = obj.type;
    field.valueBool = obj.valueBool;
    field.valueFloat = obj.valueFloat;
    field.valueLong = obj.valueLong;
    field.valueString = obj.valueString;
    field.name = obj.name;
    return field;
  }
}

export class ManagedApplicationField {
  id = "";
  name = "";
  description = "";
  required = false;
  cloneable = false;
  type = null;
  defaultValueBool = false;
  defaultValueString = "";
  defaultValueLong = 0;
  defaultValueFloat = 0.0;
  multiStringOptions = [];

  get defaultValue() {
    if (this.type == ManagedApplicationFieldTypes.BOOL) {
      return this.defaultValueBool;
    } else if (this.type == ManagedApplicationFieldTypes.STRING) {
      return this.defaultValueString;
    } else if (this.type == ManagedApplicationFieldTypes.LONG) {
      return this.defaultValueLong;
    } else if (this.type == ManagedApplicationFieldTypes.FLOAT) {
      return this.defaultValueFloat;
    } else if (this.type == ManagedApplicationFieldTypes.MULTI_STRING) {
      return this.defaultValueString;
    }
  }

  static fromAPI(obj) {
    const field = new ManagedApplicationField();
    field.id = obj.id;
    field.name = obj.name;
    field.description = obj.description;
    field.required = obj.required;
    field.cloneable = obj.cloneable;
    field.type = obj.type;
    field.defaultValueBool = obj.defaultValueBool;
    field.defaultValueFloat = obj.defaultValueFloat;
    field.defaultValueLong = obj.defaultValueLong;
    field.defaultValueString = obj.defaultValueString;
    if (obj.multiStringOptions) {
      field.multiStringOptions = obj.multiStringOptions.map(
        MultiStringOption.fromAPI
      );
    }
    return field;
  }
}

export const DomainNameType = {
  CUSTOM: "CUSTOM_DOMAIN",
  PROVIDED: "PROVIDED_DOMAIN",
};

export class DomainVerification {
  domain = "";
  cnameTarget = "";
  verified = false;
  ingressTargetCNAME = "";
  ingressTargetA = "";
  ingressVerified = false;

  static fromAPI(obj) {
    const verify = new DomainVerification();
    verify.domain = obj.domain;
    verify.cnameTarget = obj.cname_target;
    verify.verified = obj.verified;
    verify.ingressTargetCNAME = obj.ingressTargetCNAME;
    verify.ingressTargetA = obj.ingressTargetA;
    verify.ingressVerified = obj.ingressVerified;
    return verify;
  }
}

export class ManagedApplicationFeature {
  id = "";
  name = "";
  description = "";

  static fromAPI(obj) {
    const feature = new ManagedApplicationFeature();
    feature.id = obj.id;
    feature.name = obj.name;
    feature.description = obj.description;
    return feature;
  }
}

export class ManagedApplicationPlan {
  id = "";
  name = "";
  features = [];
  priceBilledMonthly = 0.0;
  priceBilledAnnually = 0.0;

  static fromAPI(obj) {
    const plan = new ManagedApplicationPlan();
    plan.id = obj.id;
    plan.name = obj.name;
    plan.priceBilledAnnually = obj.priceBilledAnnually;
    plan.priceBilledMonthly = obj.priceBilledMonthly;
    plan.features = obj.features.map(ManagedApplicationFeature.fromAPI);
    return plan;
  }
}
export class ManagedApplicationProvider {
  id = "";
  name = "";
  description = "";
  logo = "";
  configs = [];
  plans = [];

  static fromAPI(obj) {
    const provider = new ManagedApplicationProvider();
    provider.id = obj.id;
    provider.name = obj.name;
    provider.description = obj.description;
    provider.logo = obj.logo;
    provider.configs = obj.configs.map(ManagedApplicationField.fromAPI);
    provider.plans = obj.plans.map(ManagedApplicationPlan.fromAPI);
    return provider;
  }

  lookupField(id) {
    return this.configs.find((e) => e.id == id);
  }

  lookupFieldName(id) {
    const field = this.lookupField(id);
    if (field == null) return id;
    return field.name;
  }

  get requiredConfigs() {
    // todo - for clones when you set some config it won't update
    return this.configs.filter((c) => c.required);
  }

  get optionalConfigs() {
    return this.configs.filter((c) => !c.required);
  }

  getPlan(id) {
    for (var x in this.plans) {
      if (this.plans[x].id == id) {
        return this.plans[x];
      }
    }
    return null;
  }

  getCheapestPlan() {
    if (this.plans.length == 0) return null;
    return this.plans.sort((a, b) => {
      if (a.priceBilledAnnually == b.priceBilledAnnually) return 0;
      else if (a.priceBilledAnnually > b.priceBilledAnnually) return 1;
      else return -1;
    })[0];
  }
}

export class ManagedApplication {
  applicationId = "";
  applicationName = "";
  domainName = "";
  configs = [];
  state = [];
  status = "";
  domainType = "";
  planId = "";
  providerId = "";
  domainVerification = null;
  subscriptionStart = null;
  subscriptionEnd = null;
  sitePath = "";
  sftpDetails = null;
  updateStrategy = null;
  region = null;
  totalDiskUsageBytes = 0;
  allowedDiskUsageBytes = 0;

  static fromAPI(obj) {
    const app = new ManagedApplication();
    app.applicationId = obj.applicationId;
    app.applicationName = obj.applicationName;
    app.domainName = obj.domainName;
    app.configs = obj.configs.map(ManagedApplicationFieldValue.fromAPI);
    app.state = obj.state.map(ManagedApplicationFieldValue.fromAPI);
    app.status = obj.status;
    app.domainType = obj.domainType;
    app.planId = obj.planId;
    app.providerId = obj.providerId;
    app.subscriptionStart = obj.subscriptionStart;
    app.subscriptionEnd = obj.subscriptionEnd;
    app.sitePath = obj.sitePath;
    app.region = Region.fromAPI(obj.region);
    app.totalDiskUsageBytes = obj.totalDiskUsageBytes;
    app.allowedDiskUsageBytes = obj.allowedDiskUsageBytes;
    app.updateStrategy =
      obj.updateStrategy == null
        ? UpdateStrategy.DISABLED
        : UpdateStrategy.fromAPI(obj.updateStrategy);

    if (obj.sftpDetails != null) {
      app.sftpDetails = SFTPDetails.fromAPI(obj.sftpDetails);
    }

    if (obj.domainVerification != null) {
      app.domainVerification = DomainVerification.fromAPI(
        obj.domainVerification
      );
    }

    return app;
  }

  get endpoint() {
    return "https://" + this.domainName + this.sitePath;
  }

  isUpdating() {
    return this.status == "UPDATING";
  }

  inSomePendingStatus() {
    return this.status != "ACTIVE";
  }

  isDeleted() {
    return this.status == "DELETED";
  }

  canBeDeleted() {
    return this.status == "ACTIVE";
  }

  isDeleting() {
    return this.status == "DELETING";
  }
}

export class ManagedApplicationSnapshot {
  job = "";
  managedApplication = null;

  static fromAPI(obj) {
    const snapshot = new ManagedApplicationSnapshot();
    snapshot.job = ManagedApplicationJob.fromAPI(obj.job);
    snapshot.managedApplication = ManagedApplication.fromAPI(
      obj.managedApplication
    );
    return snapshot;
  }
}

export class ManagedApplicationJob {
  id = "";
  applicationId = "";
  type = "";
  status = "";
  startedAt = null;
  finishedAt = null;
  name = "";
  storageUsageBytes = 0;

  static fromAPI(obj) {
    const job = new ManagedApplicationJob();
    job.id = obj.id;
    job.name = obj.name;
    job.applicationId = obj.applicationId;
    job.type = obj.type;
    job.status = obj.status;
    job.startedAt = obj.startedAt;
    job.finishedAt = obj.finishedAt;
    job.storageUsageBytes = obj.storageUsageBytes;
    return job;
  }

  snapshotType() {
    if(this.type == "SNAPSHOT") {
      return "Manual";
    }
    else if(this.type == "AUTOMATED_SNAPSHOT") {
      return "Automated";
    }
    else {
      return "Unknown";
    }
  }

  isManualSnapshot() {
    return this.type == "SNAPSHOT";
  }

  isAutomatedSnapshot() {
    return this.type == "AUTOMATED_SNAPSHOT";
  }

  statusName() {
    if(this.status == "JOB_SUCCESS") {
      return "Complete";
    }
    else if(this.status == "JOB_PENDING") {
      return "Pending";
    }
    else if(this.status == "JOB_RUNNING") {
      return "In Progress";
    }
    else if(this.status == "JOB_ERROR") {
      return "Failed";
    }
    return "Unknown";
  }

  isFinished() {
    return this.status == "JOB_SUCCESS";
  }

  isPending() {
    return !this.sFinished && !this.isFailed();
  }

  isFailed() {
    return this.status == "JOB_ERROR";
  }
}

export class SFTPDetails {
  host = "";
  port = 0;
  password = "";
  user = "";

  static fromAPI(obj) {
    const details = new SFTPDetails();
    details.host = obj.host;
    details.port = obj.port;
    details.password = obj.password;
    details.user = obj.user;
    return details;
  }
}

export class UpdateStrategy {
  id = "";
  description = "";

  constructor(id, description) {
    this.id = id;
    this.description = description;
  }

  static DISABLED = new UpdateStrategy("DISABLED", "No automatic updates");
  static AUTOMATIC_PATCHING = new UpdateStrategy(
    "AUTOMATIC_PATCHING",
    "Install patch updates automatically (security and bug fixes)"
  );
  static AUTOMATIC_LATEST = new UpdateStrategy(
    "AUTOMATIC_LATEST",
    "Keep application fully up-to-date"
  );

  static fromAPI(obj) {
    const existing = UPDATE_STRATEGIES.find((f) => obj == f.id);
    if (existing) return existing;
    else {
      return new UpdateStrategy(obj, "");
    }
  }
}

export const UPDATE_STRATEGIES = [
  UpdateStrategy.DISABLED,
  UpdateStrategy.AUTOMATIC_PATCHING,
  UpdateStrategy.AUTOMATIC_LATEST,
];

export class BillingDetails {
  invoices = [];

  static fromAPI(obj) {
    const details = new BillingDetails();
    details.invoices = obj.invoices.map(BillingInvoice.fromAPI);
    return details;
  }
}

export class BillingInvoice {
  startDate = null;
  endDate = null;
  total = 0.0;
  invoiceId = '';
  paid = false;
  lineItems = [];
  invoiceUrl = '';
  paymentDescription = '';

  static fromAPI(obj) {
    const invoice = new BillingInvoice();
    invoice.startDate = new Date(obj.startDate);
    invoice.endDate = new Date(obj.endDate);
    invoice.total = obj.total;
    invoice.invoiceId = obj.invoiceId;
    invoice.paid = obj.paid;
    invoice.lineItems = obj.lineItems.map(BillingInvoiceLineItem.fromAPI);
    invoice.invoiceUrl = obj.invoiceUrl;
    invoice.paymentDescription = obj.paymentDescription;
    return invoice;
  }
}

export class BillingInvoiceLineItem {
  product = '';
  quantity = 0;
  unitPrice = 0.0;
  amount = 0.0;
  startDate = null;
  endDate = null;

  static fromAPI(obj) {
    const item = new BillingInvoiceLineItem();
    item.product = obj.product;
    item.quantity = obj.quantity;
    item.unitPrice = obj.unitPrice;
    item.amount = obj.amount;
    item.startDate = obj.startDate;
    item.endDate = obj.endDate;
    return item;
  }
}
import React, { useEffect, useState, useContext } from "react";
import TimeSeriesChart from "../timeseries";
import NitroContext from "../../context";

import prettyBytes from "pretty-bytes";
import Loadable from "../loadable";

export const formatPercent = (value) => {
  return (value * 100).toFixed(2) + "%";
};

export const formatBytes = (value) => {
  return prettyBytes(value);
};

export const formatDuration = (value) => {
  return (value * 1000).toFixed(0) + "ms";
};

const noFormat = (value) => value;

const MetricDisplay = (props) => {
  const {
    project,
    metricName,
    aggregationType,
    startTime,
    endTime,
    valueType = "float",
    valueFormat = noFormat,
    yAxisLabel = "",
    height = 300,
    fixedYScale,
  } = props;

  const [globalState, dispatch] = useContext(NitroContext);
  const [metricList, setMetricList] = useState(null);

  useEffect(() => {
    if (project) {
      reloadData();
    }
  }, [props.project, props.startTime, props.endTime]);

  const reloadData = async () => {
    setMetricList(null);
    const metricData = await globalState.api.getMetricsData(
      project.id,
      "",
      metricName,
      aggregationType,
      startTime,
      endTime
    );
    setMetricList(metricData.metricData);
  };

  const getValue = (d) => {
    return valueType == "float" ? d.floatValue : d.longValue;
  };

  if (metricList == null) {
    return (
      <Loadable loading={true}>
        <div className="row"></div>
      </Loadable>
    );
  } else {
    if (metricList.length == 0) {
      return (
        <div className="card-description">
          <p>No Data</p>
        </div>
      );
    }
    return (
      <TimeSeriesChart
        fixedYScale={fixedYScale}
        height={height}
        yAxisLabel={yAxisLabel}
        legendMap={(idx) =>
          metricList[idx].resourceId.length > 0
            ? metricList[idx].resourceId
            : metricList[idx].applicationId
        }
        formatTooltipX={(d) => d.timestamp.toISOString()}
        formatTooltipY={(d) => valueFormat(getValue(d))}
        xMap={(value) => value.timestamp}
        yMap={getValue}
        series={metricList.map((m) => m.samples)}
        formatTickY={(d) => valueFormat(d)}
      />
    );
  }
};
export default MetricDisplay;

import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Alert,
  Select,
  Statistic,
  Collapse,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import NitroContext from "../../context";
import { ConflictError } from "../../service/nitroapi";
import { Link, Redirect } from "react-router-dom";
import { BadRequestError } from "../../service/nitroapi/index";
import Loadable from "../../components/loadable";
import { List, Divider } from "antd";
import { useLocation } from "react-router-dom";
import FieldEditor from "../../components/managed-apps/field-editor";
import {
  BillingType,
  ManagedApplicationFieldValue,
  UpdateStrategy,
  UPDATE_STRATEGIES,
} from "../../service/nitroapi/model";
import DomainSelector from "../../components/domain-selector";
import { useForm } from "antd/lib/form/Form";
const { Panel } = Collapse;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SiteLaunchPage = (props) => {
  const [deployState, setDeployState] = useState({
    phase: "select_provider",
  });
  const query = useQuery();
  const [form] = useForm();
  const [globalState] = useContext(NitroContext);
  const [{ project: selectedProject }] = useContext(NitroContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [application, setApplication] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [planNotAvailableTrial, setPlanNotAvailableTrial] = useState(false);

  const [providers, setProviders] = useState([]);

  const [snapshot, setSnapshot] = useState(null);

  useEffect(() => {
    loadRegions();
    loadProviders();
    loadSnapshot();
  }, []);

  useEffect(() => {
    loadSnapshot();
  }, [selectedProject]);

  useEffect(() => {
    const providerId = query.get("providerId");
    if (providerId != null) {
      const provider = providers.find((p) => p.id == providerId);
      if (provider != null) {
        onSelectProvider(provider);
      }
    }
  }, [providers]);

  const loadSnapshot = async () => {
    if (selectedProject == null) return;
    const snapshotId = query.get("snapshotId");
    if (snapshotId != null) {
      try {
        const snapshot = await globalState.api.getManagedApplicationSnapshot(
          selectedProject.id,
          snapshotId
        );
        setSnapshot(snapshot);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const loadRegions = async () => {
    try {
      const regions = await globalState.api.getRegions();
      setRegions(regions);
    } catch (e) {
      console.log(e);
    }
  };

  /*const onUseCustomDomainChange = (e) => {
    setSubdomainName("");
    setUseCustomDomain(e.target.value);
  };*/

  const loadProviders = async () => {
    try {
      const providers = await globalState.api.getManagedApplicationProviders();
      setProviders(providers);
      //console.log(providers);
    } catch (e) {
      console.log(e);
    }
  };

  const validateSubdomain = () => {
    console.log(form);
  };

  const onSelectPlan = (plan) => {
    if(globalState.userInfo.isTrial() && plan.id != 'basic') {
      setPlanNotAvailableTrial(true);
      return;
    }
    setPlanNotAvailableTrial(false);
    setDeployState({
      ...deployState,
      phase: "site_configure",
      selectedPlan: plan,
    });
  };

  const onSelectProvider = (provider) => {
    setDeployState({
      ...deployState,
      phase: "choose_plan",
      selectedProvider: provider,
    });
  };

  const onSelectRegion = (value) => {
    setSelectedRegion(regions[value].id);
  };

  /*const onSubdomainNameChange = (e) => {
    console.log(e.target.value);
    setSubdomainName(e.target.value);
    validateSubdomain(e.target.value);
  };*/

  const onFinish = async (values) => {
    try {
      setIsCreating(true);
      const domainType = values.useCustomDomain
        ? "CUSTOM_DOMAIN"
        : "PROVIDED_DOMAIN";
      const domainName = values.useCustomDomain
        ? values.customDomainName
        : values.subdomainName;
      const path = values.customDomainNamePath;
      var configValues = [];
      deployState.selectedProvider.configs.forEach((field) => {
        const value = values["appConfig-" + field.id];
        if (value != null) {
          configValues.push(
            ManagedApplicationFieldValue.buildForFieldWithValue(field, value)
          );
        }
      });
      const application = await globalState.api.launchManagedApplication(
        selectedProject.id,
        values.siteName,
        domainType,
        domainName,
        deployState.selectedPlan.id,
        deployState.selectedProvider.id,
        selectedRegion,
        configValues,
        BillingType.MONTHLY,
        snapshot != null ? snapshot.job.id : "",
        path,
        values.updateStrategy
      );
      setApplication(application);
    } catch (e) {
      setIsCreating(false);
      console.log(e);
      if (e instanceof ConflictError) {
        setErrorMessage("Error: " + e.message);
      } else if (e instanceof BadRequestError && e.message) {
        setErrorMessage("Error: " + e.message);
      } else {
        setErrorMessage("Unable to deploy application at this time");
      }
    }
  };

  const handleSubmit = async (e) => {
    console.log("do it");
    e.preventDefault();
    console.log(form);
    console.log(form.validateFields);
    await form
      .validateFields()
      .then((values) => {
        try {
          setIsCreating(true);
          console.log(deployState);
          console.log(values);
          //const response = await globalState.api.createApplication(selectedProject.id, values.appId, yaml.safeLoad(atob(deployState.selectedTemplate.template)), selectedRegion);
          //setDeployed(true);
        } catch (e) {
          setIsCreating(false);
          if (e instanceof ConflictError) {
            setErrorMessage("Application ID already exists");
          } else if (e instanceof BadRequestError && e.message) {
            setErrorMessage("Error: " + e.message);
          } else {
            setErrorMessage("Unable to deploy application at this time");
          }
        }
      })
      .catch((err) => {});
  };

  const getPage = (children) => <div>{children}</div>;
  if (application != null) {
    return getPage(
      <Redirect
        to={{
          pathname: "/sites/" + encodeURIComponent(application.applicationId),
        }}
      />
    );
  }
  if (deployState.phase == "select_provider") {
    return getPage(
      <Loadable loading={providers.length == 0}>
        <h3>What type of site do you want to launch?</h3>
        <p>
          Nitro Powered makes it easy to launch any type of site. All sites are fully
          managed for you, available with the click of a button.
        </p>
        <Row justify="left" gutter={6}>
          {providers.map((provider) => (
            <Col key={provider.id} xs={24} sm={4} span={4}>
              <Col>
                <Card
                  onClick={() => onSelectProvider(provider)}
                  style={{width: 'auto'}}
                  cover={
                    <img
                      style={{
                        minWidth: '100px',
                        maxWidth: "200px",
                        height: "auto",
                        objectFit: "scale-down",
                      }}
                      alt={provider.id}
                      src={provider.logo}
                    />
                  }
                  hoverable
                >
                  <Card.Meta
                    title={provider.name}
                    description={provider.description}
                  />{" "}
                  <br />
                  <Statistic
                    title="Plans starting at"
                    valueStyle={{ fontSize: "1.3em" }}
                    value={
                      "$" +
                      provider.getCheapestPlan().priceBilledAnnually +
                      "/month"
                    }
                  />
                </Card>
              </Col>
            </Col>
          ))}
        </Row>
      </Loadable>
    );
  } else if (deployState.phase == "choose_plan") {
    return getPage(
      <div>
        <Row>
          <Col>
            <h3>Select a Billing Plan</h3>
            <p>
              Choose a plan for your{" "}
              <strong>{deployState.selectedProvider.name}</strong> site. Don't
              worry, you can always upgrade later.
            </p>
          </Col>
        </Row>
          {planNotAvailableTrial ? <div><Alert type="error" message={(
            <div>This plan is only available for paid customers. Please <Link to="/billing">enter your billing information</Link> if you would like to select this plan.</div>
          )}/><br/></div> : ''}
        <Row justify="left" gutter={6}>
          {deployState.selectedProvider.plans.map((plan) => (
            <Col key={plan.id} span={6} xs={24} sm={6}>
              <Card onClick={() => onSelectPlan(plan)} hoverable>
                <Card.Meta title={plan.name + " Plan"} /> <br />
                <Statistic
                  title="Billed Monthly"
                  valueStyle={{ fontSize: "1.2em" }}
                  value={"$" + plan.priceBilledMonthly + "/month"}
                />
                <Divider orientation="left">Features</Divider>
                <List
                  size="small"
                  bordered
                  dataSource={plan.features}
                  renderItem={(item) => <List.Item>{item.name}</List.Item>}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <br />
          <br />
          <br />
          <Button
            type="primary"
            onClick={() => {
              setDeployState({ ...deployState, phase: "select_provider" });
            }}
          >
            <LeftOutlined /> Back
          </Button>
          &nbsp;
        </Row>
      </div>
    );
  } else if (deployState.phase == "site_configure") {
    return getPage(
      <div>
        <h3>Configure your new site's settings</h3>
        <p>
          Fill out the information below to create your{" "}
          <strong>{deployState.selectedProvider.name}</strong> site.
        </p>
        {snapshot != null ? (
          <div>
            <Alert
              message={
                "This site will be restored from a snapshot of " +
                snapshot.managedApplication.applicationName
              }
              type="info"
            />
            <br />
          </div>
        ) : (
          ""
        )}
        <div className="card card-default">
          <div className="card-body">
            <p>
              Selected Plan: <strong>{deployState.selectedPlan.name}</strong> ($
              {deployState.selectedPlan.priceBilledMonthly}/month)
            </p>
            <Form
              id="launchForm"
              form={form}
              labelCol={{ sm: 3 }}
              wrapperCol={{ sm: 21, xs: 22 }}
              onFinish={onFinish}
              onSubmit={handleSubmit}
            >
              <Form.Item
                name="siteName"
                label="Site Name"
                rules={[{ required: true, message: "Site Name is required" }]}
              >
                <Input style={{ width: "100%" }} maxLength={128} />
              </Form.Item>
              <DomainSelector
                useCustomDomainFieldName="useCustomDomain"
                customDomainFieldName="customDomainName"
                customDomainPathFieldName="customDomainNamePath"
                subdomainFieldName="subdomainName"
              />
              <Form.Item
                name="region"
                label="Region"
                rules={[{ required: true, message: "Region required" }]}
              >
                <Select
                  showSearch
                  autoComplete="newpassword"
                  style={{ width: '100%' }}
                  placeholder="Select a Region"
                  optionFilterProp="children"
                  onChange={onSelectRegion}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {regions.map((r, idx) => (
                    <Select.Option key={r.id} value={idx}>
                      {r.toString()}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                initialValue={UpdateStrategy.AUTOMATIC_LATEST.id}
                name="updateStrategy"
                label="Automatic Updates"
              >
                <Select style={{ width: '100%' }}>
                  {UPDATE_STRATEGIES.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Collapse defaultActiveKey={["1"]}>
                  <Panel style={{display: deployState.selectedProvider.requiredConfigs.length == 0 ? 'none' : 'block'}}  header="Site Settings" key="1">
                    <FieldEditor
                      fields={deployState.selectedProvider.requiredConfigs}
                    />
                  </Panel>
                  <Panel style={{display: deployState.selectedProvider.optionalConfigs.length == 0 ? 'none' : 'block'}}  header="Advanced Options" key="2">
                    <FieldEditor
                      fields={deployState.selectedProvider.optionalConfigs}
                    />
                  </Panel>
                </Collapse>
              </Form.Item>

              {errorMessage != "" ? (
                <Form.Item>
                  {errorMessage != "" ? (
                    <div>
                      <Alert type="error" message={errorMessage} />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Item>
              ) : (
                ""
              )}
            </Form>
          </div>
        </div>
        <Button
          type="primary"
          onClick={() => {
            setDeployState({ ...deployState, phase: "choose_plan" });
          }}
        >
          <LeftOutlined />
          Back
        </Button>
        &nbsp;
        <Button
          type="primary"
          form="launchForm"
          htmlType="submit"
          loading={isCreating}
        >
          Launch
        </Button>
      </div>
    );
  }
};
export default SiteLaunchPage;
